import { Component } from '@angular/core';
import { VerticalTabsComponent } from 'src/app/components/common/vertical-tabs/vertical-tabs.component';

@Component({
    selector: 'app-admin',
    imports: [VerticalTabsComponent],
    templateUrl: './admin.component.html',
    styleUrl: './admin.component.scss'
})
export class AdminComponent {
  tabs = [
    { title: 'Accounts', id: 'admin.accounts.tab', path: '/admin/accounts' },
    { title: 'Positions', id: 'admin.positions.tab', path: '/admin/positions' },
    { title: 'Roles', id: 'admin.roles.tab', path: '/admin/roles' },
    { title: 'Permissions', id: 'admin.permissions.tab', path: '/admin/permissions' }
  ];
}
