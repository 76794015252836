import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataServiceBase } from './dataServiceBase';

export interface State {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class AppStateService extends DataServiceBase implements OnDestroy {
  private stateSubject = new BehaviorSubject<string>('');
  state$ = this.stateSubject.asObservable();
  private state: State = {};
  private previousState: string = '';
  private saveInterval: any;
  private isDirty = false;

  protected override async initialize() {
    try {
      const loadedState = await this.loadState();
      this.state = loadedState || {};
      this.previousState = JSON.stringify(this.state);
      this.stateSubject.next('');
      this.startAutoSave();
      this.isDirty = false;
    } catch (error) {
      console.error('Failed to load state:', error);
    }
  }

  public setState(key: string, value: any): void {
    this.state[key] = JSON.stringify(value);
    this.stateSubject.next(key);
    this.isDirty = true;
  }

  public getState(key: string): any {
    return this.state[key];
  }

  public flushState(): void {
    this.state = {};
    this.stateSubject.next('');
  }

  private loadState(): Promise<any> {
    return Promise.resolve(null);
    //return this.get('ui/mystate');
  }

  private async saveState(): Promise<void> {
    if (!this.isDirty) return;
    var strState = JSON.stringify(this.state);
    console.log(strState);
    this.isDirty = false;
    
    try {
      //await this.post('ui/mystate', this.state);
      this.previousState = JSON.stringify(this.state);
      console.log('State saved successfully');
    } catch (error) {
      console.error('Failed to save state:', error);
    }
  }

  private startAutoSave(): void {
    this.saveInterval = setInterval(async () => {
      const currentState = JSON.stringify(this.state);
      if (currentState !== this.previousState) {
        await this.saveState();
      }
    }, 5000);
  }

  ngOnDestroy(): void {
    if (this.saveInterval) {
      clearInterval(this.saveInterval);
    }
  }
}
