import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

interface TableColumn {
  key: string;
  label: string;
  isCheckbox?: boolean;
  width?: string;
}

interface TableRow {
  [key: string]: any;
}

@Component({
    selector: 'app-selector-table',
    imports: [CommonModule],
    templateUrl: './selector-table.component.html',
    styleUrls: ['./selector-table.component.scss']
})
export class SelectorTableComponent {
  @Input() columns: TableColumn[] = [];
  @Input() rows: TableRow[] = [];

  onCheckboxChange(row: TableRow, key: string, event: Event) {
    const checkbox = event.target as HTMLInputElement;
    row[key] = checkbox.checked;
  }

  isRowDisabled(row: TableRow): boolean {
    return !row['selected'];
  }
}
