import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { HomeComponent } from '../app/components/layout/pages/home/home.component';
import { AdminComponent } from '../app/components/layout/pages/admin/admin.component';
import { UsersComponent } from '../app/components/layout/pages/users/users.component';
import { ProfileComponent } from '../app/components/layout/pages/profile/profile.component';
import { PermissionsComponent } from './components/layout/pages/admin/permissions/permissions.component';
import { AccountsComponent } from './components/layout/pages/admin/accounts/accounts.component';
import { RolesComponent } from './components/layout/pages/admin/roles/roles.component';
import { ReleaseNotesComponent } from './components/layout/pages/release-notes/release-notes.component';
import { FeedbackComponent } from './components/layout/pages/feedback/feedback.component';
import { PositionsComponent } from './components/layout/pages/admin/positions/positions/positions.component';
import { ComponentTestPageComponent } from './components/layout/pages/developer/component-test-page/component-test-page.component';
import { SuperadminComponent } from './components/layout/pages/superadmin/superadmin.component';
import { DeveloperComponent } from './components/layout/pages/developer/developer.component';
import { TeamReadinessComponent } from './components/layout/pages/team-readiness/team-readiness.component';
import { TeamRosterComponent } from './components/layout/pages/team-roster/team-roster.component';
import { TrainingComponent } from './components/layout/pages/training/training.component';
import { MyTrainingComponent } from './components/layout/pages/my-training/my-training.component';
import { ReportsComponent } from './components/layout/pages/reports/reports.component';
import { TaskSummariesComponent } from './components/layout/pages/task-summaries/task-summaries.component';
import { GearComponent } from './components/layout/pages/gear/gear.component';
import { OrdersComponent } from './components/layout/pages/orders/orders.component';
import { FailedComponent } from './components/layout/pages/failed/failed.component';

export const routes: Routes = [
  {path: '', redirectTo: 'profile', pathMatch: 'full'},
  {path: 'profile/:id', component: ProfileComponent, canActivate: [MsalGuard]},
  {path: 'login-failed', component: FailedComponent},
  {path: 'teamreadiness', component: TeamReadinessComponent},
  {path: 'teamroster', component: TeamRosterComponent},
  {path: 'training', component: TrainingComponent},
  {path: 'mytraining', component: MyTrainingComponent},
  {path: 'reports', component: ReportsComponent},
  {path: 'tasksummaries', component: TaskSummariesComponent},
  {path: 'gear', component: GearComponent},
  {path: 'orders', component: OrdersComponent},
  {path: 'admin', component: AdminComponent, canActivate: [MsalGuard],
    children: [
      {path: '', redirectTo: 'accounts', pathMatch: 'full'},
      {path: 'accounts', component: AccountsComponent, canActivate: [MsalGuard]},
      {path: 'roles', component: RolesComponent, canActivate: [MsalGuard]},
      {path: 'permissions', component: PermissionsComponent, canActivate: [MsalGuard]},
      {path: 'positions', component: PositionsComponent, canActivate: [MsalGuard]}
    ]
  },
  {path: 'superadmin', component: SuperadminComponent, canActivate: [MsalGuard],
    children: [
      {path: '', redirectTo: 'componenttestpage', pathMatch: 'full'},
      {path: 'componenttestpage', component: ComponentTestPageComponent, canActivate: [MsalGuard]}
    ]
  },
  {path: 'developer', component: DeveloperComponent, canActivate: [MsalGuard],
    children: [
      {path: '', redirectTo: 'componenttestpage', pathMatch: 'full'},
      {path: 'componenttestpage', component: ComponentTestPageComponent, canActivate: [MsalGuard]}
    ]
  },
  {path: 'home', component: HomeComponent, canActivate: [MsalGuard]},
  {path: 'users', component: UsersComponent, canActivate: [MsalGuard]},
  {path: 'feedback', component: FeedbackComponent, canActivate: [MsalGuard]},    
  {path: 'releasenotes', component: ReleaseNotesComponent, canActivate: [MsalGuard]},    
];

