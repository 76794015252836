<div class="component-test-page-wrapper" [class.inDebugMode]="isDebugMode$ | async">

  <h2 class="title">Components:</h2>

  <app-autocomplete-activedirectory class="test-input-box" [id]="pageId + '.selectAccount'" [labelPosition]="'left'"
    [label]="'Account:'" (valueChange)="onValueChanged($event)" [listHeight]="'400px'" />

  <app-spacer />

  <div class="inputs">
    <app-inputbox [id]="pageId + '.inputTest'" class="leftInput" [label]="'Input:'" [(value)]="model.role" [validationOptions]="{required: true}" />
    <app-inputbox [id]="pageId + '.multilineTest'" class="rightInput" [label]="'Multiline:'" [multiline]="true"
      [(value)]="model.description" />
  </div>
  <div class="outputs">
    <p>Input: {{ model.role }}</p>
    <p>Multi: {{ model.description }}</p>
  </div>

  <app-spacer />

  <div class="otherControls">
    <app-dropdown 
      [limitToList]="true" 
      [id]="pageId + '.dropdownTest'" 
      [label]="'Dropdown:'" 
      [items]="model.items" 
    />
  </div>

  <app-spacer />

  <app-checkbox 
    [id]="pageId + '.checkboxTest'" 
    [label]="'Checkbox:'"
    [errors]="!groupValid ? 'This value only needs to be set once the page is valid.' : ''"
    [value]="pageState.checkboxTestValue"
    (valueChange)="pageState.checkboxTestValue = $event"
  />

  <app-spacer />

  <app-dual-list
    [id]="pageId + '.dualListTest'" 
    [leftList]="model.items" 
    [rightList]="model.items"
  />

  <app-spacer />

  <div class="outputs">
    <p>AllStates: {{ allValid }}</p>
  </div>

  <app-spacer />
  
  <h2>Validators:</h2>

  <app-inputbox [id]="pageId + '.required'" [label]="'Required:'" [(value)]="pageState.required"
    [validationOptions]="{required: true}" [validationGroup]="'testValidationGroup'" [labelWidth]="'120px'" />
  <app-spacer />
  <app-inputbox [id]="pageId + '.alphaonly'" [label]="'AlphaOnly:'" [(value)]="pageState.alphaOnly"
    [validationOptions]="{alphaOnly: true}" [validationGroup]="'testValidationGroup'" [labelWidth]="'120px'" />
  <app-spacer />
  <app-inputbox [id]="pageId + '.numericonly'" [label]="'NumericOnly:'" [(value)]="pageState.numericOnly"
    [validationOptions]="{numericOnly: true}" [validationGroup]="'testValidationGroup'" [labelWidth]="'120px'" />
  <app-spacer />
  <app-inputbox [id]="pageId + '.alphanumeric'" [label]="'AlphaNumeric:'" [(value)]="pageState.alphaNumeric"
    [validationOptions]="{alphaNumeric: true}" [validationGroup]="'testValidationGroup'" [labelWidth]="'120px'" />
  <app-spacer />
  <div class="squoze">
    <app-inputbox [id]="pageId + '.minlength'" [label]="'MinLength:'" [(value)]="pageState.minLength"
      [validationOptions]="{minLength: 3}" [validationGroup]="'testValidationGroup'" [labelWidth]="'120px'" />
    <app-spacer />
    <app-inputbox [id]="pageId + '.maxlength'" [label]="'MaxLength:'" [(value)]="pageState.maxLength"
      [validationOptions]="{maxLength: 5}" [validationGroup]="'testValidationGroup'" [labelWidth]="'120px'" />
    <app-spacer />
    <app-inputbox [id]="pageId + '.multipleTests'" [label]="'MultipleTests:'" [(value)]="pageState.multipleTests"
      [validationOptions]="{required: true, alphaOnly: true, minLength: 3, maxLength: 15}" [validationGroup]="'testValidationGroup'" [labelWidth]="'120px'" />
  </div>
  <app-spacer />
  <div [style]="{ width: '500px' }">
    <app-dropdown [id]="pageId + '.dropdownTest'" [label]="'Dropdown:'" [items]="model.items"
      [validationOptions]="{ required: true }" [validationGroup]="'testValidationGroup'" [labelWidth]="'120px'" />
  </div>
  <app-spacer />
  <app-dropdown 
    [limitToList]="true" 
    [id]="pageId + '.dropdownLimitTest'" 
    [label]="'DropdownLimit:'" 
    [items]="model.items"
    [validationOptions]="{required: true}" 
    [labelWidth]="'120px'" 
    [value]="pageState.dropdownLimitTestValue"
    (valueChange)="onValueChanged($event)"
    [validationGroup]="'testValidationGroup'" 
  />
  <p>Value: {{ pageState.dropdownLimitTestValue }}</p>
  <app-spacer />

  <div class="outputs">
    <p>Valid: {{ groupValid }}</p>
  </div>


  <h2>Components:</h2>

  <app-autocomplete-activedirectory class="test-input-box" [id]="pageId + '.selectAccount'" [labelPosition]="'left'"
    [label]="'Account:'" (valueChange)="onValueChanged($event)" [listHeight]="'400px'" />

  <app-spacer />

  <div class="inputs">
    <app-inputbox [id]="pageId + '.inputTest'" class="leftInput" [label]="'Input:'" [(value)]="model.role" />
    <app-inputbox [id]="pageId + '.multilineTest'" class="rightInput" [label]="'Multiline:'" [multiline]="true"
      [(value)]="model.description" />
  </div>

  <app-spacer />

  <div class="otherControls">
    <app-dropdown [id]="pageId + '.dropdownTest'" [label]="'Dropdown:'" [items]="model.items" />
  </div>

  <app-spacer />

  <h2>Validators:</h2>

  <app-inputbox [id]="pageId + '.required'" [label]="'Required:'" [(value)]="model.role" [labelWidth]="'120px'" />
  <app-spacer />
  <app-inputbox [id]="pageId + '.required'" [label]="'Required:'" [(value)]="model.role" [labelWidth]="'120px'" />
  <app-spacer />
  <app-inputbox [id]="pageId + '.required'" [label]="'Required:'" [(value)]="model.role" [labelWidth]="'120px'" />
  <app-spacer />
  <app-inputbox [id]="pageId + '.required'" [label]="'Required:'" [(value)]="model.role" [labelWidth]="'120px'" />
  <app-spacer />
  <app-inputbox [id]="pageId + '.required'" [label]="'Required:'" [(value)]="model.role" [labelWidth]="'120px'" />
  <app-spacer />
  <app-inputbox [id]="pageId + '.required'" [label]="'Required:'" [(value)]="model.role" [labelWidth]="'120px'" />
  <app-spacer />



  <div class="end-of-page-spacer"></div>

</div>