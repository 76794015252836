import { Component } from '@angular/core';

@Component({
    selector: 'app-positions',
    imports: [],
    templateUrl: './positions.component.html',
    styleUrl: './positions.component.scss'
})
export class PositionsComponent {

}
