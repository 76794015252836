<div class="dropdown-wrapper">
  <div class="input-container">
    <app-label class="dropdown-label" [text]="label" [id]="id" [style.min-width]="labelWidth" />
    <div class="dropdownInputAndButton">
      <app-validator [options]="validationOptions" [value]="value" [errors]="errors" [group]="validationGroup">
        <input
          #textInput
          class="dropdown-input"
          [placeholder]="placeholder"
          [(ngModel)]="value"
          (input)="onInputChange($event)"
          (focus)="onFocusGained($event)"
          (focusout)="onFocusLost($event)"
        />
      </app-validator>
      <button class="dropdown-button" (click)="toggleDropdown()" tabindex="-1">▼</button>
      <app-info [id]="id"></app-info>
      <div *ngIf="isDropdownOpen" class="dropdown-list">
        <div
          *ngFor="let item of sortedItems; let i = index"
          (mousedown)="preventDropdownClose($event)" 
          (click)="onMouseSelect($event, item.name)"
          [class.selected]="i === selectedItemIndex"
          class="dropdown-item"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</div>
