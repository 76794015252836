import { Component } from '@angular/core';

@Component({
  selector: 'login-failed',
  template: `
    <p>Login failed.
    <p>This is usually a result of a bad MSAL (Microsoft Single-Sign-On) response.
    <p>We are still working to fix this error.
    <p>In the meantime, you can press Ctrl+Shift+Refresh to do a "hard refresh".
    <br>You will then need to manually navigate back to your home page.
    <p>We're sorry for the inconvenience.`,
  styleUrls: [],
  standalone: true,
})
export class FailedComponent {}
