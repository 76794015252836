import { Component } from '@angular/core';
import { VerticalTabsComponent } from 'src/app/components/common/vertical-tabs/vertical-tabs.component';

@Component({
    selector: 'app-superadmin',
    imports: [VerticalTabsComponent],
    templateUrl: './superadmin.component.html',
    styleUrl: './superadmin.component.scss'
})
export class SuperadminComponent {
  tabs = [
    { title: 'Test Page', id: '', path: '/developer/componenttestpage' },
  ];
}
