export class AssignmentHistory {
  officeNameLong: string = 'San Francisco';
  officeNameShort: string = "SF";
  residentAgency: string = 'San Francisco HQ';
  teamNameLong: string = 'Evidence Response Team Unit';
  teamNameShort: string = 'ERTU';
  positionNameLong: string = 'Task Force Officer';
  positionNameShort: string = 'TFO';
  roleNameLong: string = 'Regional MAPA';
  roleNameShort: string = 'RMAPA';
  allocation: string = 'Primary';
  designation: string = 'SMP';
  startDate: Date = new Date('1/1/2020');
  endDate: Date = new Date('3/5/2022');

  static MockData: AssignmentHistory[] = [
    Object.assign(new AssignmentHistory(), {
      officeNameLong: 'San Francisco',
      residentAgency: 'San Francisco HQ',
      teamNameLong: 'Evidence Response Team Unit',
      roleNameLong: 'Regional MAPA',
      startDate: new Date('2022-03-15'),
      endDate: new Date('2024-07-10')
    }),
    Object.assign(new AssignmentHistory(), {
      officeNameLong: 'Boston',
      residentAgency: 'unknown',
      teamNameLong: 'Foo Test Team',
      roleNameLong: 'Senior Team Lead',
      startDate: new Date('2019-09-03'),
      endDate: new Date('2021-11-28')
    }),
    Object.assign(new AssignmentHistory(), {
      officeNameLong: 'New York',
      residentAgency: 'unknown',
      teamNameLong: 'Foo Test Team',
      roleNameLong: 'Team Lead',
      startDate: new Date('2016-04-22'),
      endDate: new Date('2018-12-15')
    }),
    Object.assign(new AssignmentHistory(), {
      officeNameLong: 'Houston',
      residentAgency: 'unknown',
      teamNameLong: 'Foo Test Team',
      roleNameLong: 'Team Member',
      startDate: new Date('2013-02-14'),
      endDate: new Date('2015-08-05')
    }),
    Object.assign(new AssignmentHistory(), {
      officeNameLong: 'Baltimore',
      residentAgency: 'unknown',
      teamNameLong: 'Foo Test Team',
      roleNameLong: 'Team Member',
      startDate: new Date('2010-06-11'),
      endDate: new Date('2012-10-23')
    })
  ];
}
