<div class="permissions-wrapper">
  <div class="role-details">
    <div class="selected-role-group">
      <app-autocomplete-list
        [label]="'Selected Role:'"
        [id]="'admin.permissions.selectedrole'"
        (inputChanged)="onInputChanged($event)"
        (itemSelected)="onItemSelected($event)"
      ></app-autocomplete-list>

      <app-info [id]="'admin.permissions.selectedrole'"></app-info>
    </div>

    <app-autocomplete-list
      class="role-details-input"
      [label]="'Role Details:'"
      (inputChanged)="onInputChanged($event)"
      (itemSelected)="onItemSelected($event)"
    ></app-autocomplete-list>
  </div>

  <app-selector-table [columns]="columns" [rows]="permissions"></app-selector-table>
</div>
