import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface ErrorInfo {
  message: string;
  innerMessage: string;
  stack?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private errors: ErrorInfo[] = [];
  private errorSubject = new BehaviorSubject<ErrorInfo[]>(this.errors);
  newError$ = this.errorSubject.asObservable();
  
  addError(msg: string, error: Error): void {
    this.errors.push({message: msg, innerMessage: error.message, stack: error.stack});
    this.errorSubject.next(this.errors);
  }

}
