import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../services/error.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private errSvc: ErrorService) {}

  handleError(error: Error): void {
    if (error instanceof HttpErrorResponse) {
      this.errSvc.addError('Server-side error', error);
    } else {
      this.errSvc.addError('Client-side error', error);
    }
  }
}
