import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-widget-base',
  imports: [],
  templateUrl: './widget-base.component.html',
  styleUrl: './widget-base.component.scss'
})
export class WidgetBaseComponent {
  @Input() title: string = 'Title not set.';
}
