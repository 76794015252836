import { Component } from '@angular/core';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';

@Component({
  selector: 'app-requirements-respirators-widget',
  imports: [WidgetBaseComponent],
  templateUrl: './requirements-respirators-widget.component.html',
  styleUrl: './requirements-respirators-widget.component.scss'
})
export class RequirementsRespiratorsWidgetComponent {

}
