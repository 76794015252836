import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LabelComponent } from "../label/label.component";
import { ValidatorComponent } from "../../validator/validator.component";
import { InfoComponent } from "../../info/info.component";

interface ListItem {
  id: number | string;
  name: string;
  sortOrder?: number;
}

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    imports: [FormsModule, CommonModule, LabelComponent, ValidatorComponent, InfoComponent]
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() id: string = '';
  @Input() label: string = 'Label:';
  @Input() placeholder: string = 'Select an option';
  @Input() value: string = '';
  @Input() items: ListItem[] = [];
  @Input() limitToList: boolean = false;
  @Input() validationOptions: any = {};
  @Input() errors: string = '';
  @Input() validationGroup: string = '';
  @Input() labelWidth: string = '10px';
  @Output() valueChange = new EventEmitter<string>();

  @ViewChild('textInput', { static: false }) textInputRef!: ElementRef<HTMLInputElement>;

  sortedItems: ListItem[] = [];
  isDropdownOpen = false;
  selectedItemIndex: number = -1;
  initialValue: string = '';
  parentValue: string = '';

  constructor() { }

  ngOnInit() {
    this.sortedItems = this.sortItems(this.items);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      // Monitors external changes of "value" by the parent. 
      // May not have a use case for this yet...
      const previousValue = changes['value'].previousValue;
      const currentValue = changes['value'].currentValue;
    }
  }

  // Monitors changes made to "value" by the user.
  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    const oldIndex = this.selectedItemIndex;

    // No matter what, make sure the dropdown list is open.
    this.openDropdown();

    // With the current value, is the item in the list?
    var selectedIndex = this.getItemInList(value);

    // Now we act in one of two ways.  If they are 'limitToList':
    if (this.limitToList) {
      // If the item isn't in the list, we do nothing at all.
      if (selectedIndex == -1) return;
      // If we're "limitToList" and the selected index hasn't changed, do nothing.
      if (oldIndex == selectedIndex) return;
    }

    // Has the selected index changed?
    if (this.selectedItemIndex != selectedIndex) {
      this.selectedItemIndex = selectedIndex;
      this.scrollToSelectedItem(true);
    }

    // See if the parent needs to be made aware of the changes.
    this.emitChangesToParent();
  }

  private emitChangesToParent() {
    // We only want the "emit" to happen when actual changes take place.
    if (this.value == this.parentValue) return;
    // Keep track of what the parent "knows" about this value.
    this.parentValue = this.value;
    // Let the parent know changes have taken place.
    console.log("Changes emitted...")
    this.valueChange.emit(this.value);
  }


  sortItems(items: ListItem[]): ListItem[] {
    return items.map(item => ({ ...item })).sort((a, b) => {
      const sortOrderA = a.sortOrder ?? Number.MAX_SAFE_INTEGER;
      const sortOrderB = b.sortOrder ?? Number.MAX_SAFE_INTEGER;
      return sortOrderA - sortOrderB || a.name.localeCompare(b.name);
    });
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      this.scrollToSelectedItem();
    }
  }

  selectOption() {
    // Is the item in the list?
    const selectedIndex = this.selectedItemIndex;
    // If the item's not in the list, are we on 'limitToList?'
    if (selectedIndex == -1 && this.limitToList) {
      // This isn't doable, so we have to undo.
      this.undoChanges();
      this.isDropdownOpen = false;
      this.emitChangesToParent();
      return;
    }
    this.value = this.sortedItems[selectedIndex].name;
    this.emitChangesToParent();
    this.closeDropdown();
  }


  private getItemInList(value: string): number {
    const matchIndex = this.sortedItems.findIndex(item =>
      item.name.toLowerCase().startsWith(value.toLowerCase())
    );
    return matchIndex !== -1 ? matchIndex : -1;
  }


  scrollToSelectedItem(jumpToTop: boolean = false) {
    // Nothing to scroll to if nothing is selected.
    if (this.selectedItemIndex === -1) return;
    setTimeout(() => {// Ensure DOM updates before scrolling
      const dropdownList = document.querySelector('.dropdown-list') as HTMLElement;
      const selectedItem = document.querySelector('.dropdown-item.selected') as HTMLElement;

      if (dropdownList && selectedItem) {
        if (jumpToTop) {
          // Scroll the item to the top of the dropdown
          dropdownList.scrollTop = selectedItem.offsetTop;
        } else {
          const dropdownRect = dropdownList.getBoundingClientRect();
          const selectedItemRect = selectedItem.getBoundingClientRect();

          // Ensure the item is visible within the dropdown viewport
          if (selectedItemRect.top < dropdownRect.top) {
            dropdownList.scrollTop -= dropdownRect.top - selectedItemRect.top;
          } else if (selectedItemRect.bottom > dropdownRect.bottom) {
            dropdownList.scrollTop += selectedItemRect.bottom - dropdownRect.bottom;
          }
        }
      }
    }, 0);
  }

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      this.navigateOptionsWithArrowKeys(1); // Move down
      event.preventDefault();
    } else if (event.key === 'ArrowUp') {
      this.navigateOptionsWithArrowKeys(-1); // Move up
      event.preventDefault();
    } else if (event.key === 'Enter') {
      this.selectOption(); // Select the currently highlighted item
      event.preventDefault();
    } else if (event.key === 'Escape') {
      this.undoChanges(); // Close dropdown and revert value
      event.preventDefault();
    } else if (event.key === 'Tab') {
      this.selectOption(); // Confirm selection on Tab
      this.closeDropdown(); // Close dropdown on Tab
    }
  }

  navigateOptionsWithArrowKeys(direction: number) {
    // If the dropdown isn't currently open, only open it. Don't do any navigation.
    if (!this.isDropdownOpen) {
      this.openDropdown();
      return;
    }

    // Get the new index.
    let newIndex = this.selectedItemIndex + direction;

    // Wrap around logic
    if (newIndex < 0) {
      this.selectedItemIndex = this.sortedItems.length - 1; // Move to the last item
    } else if (newIndex >= this.sortedItems.length) {
      this.selectedItemIndex = 0; // Move to the first item
    } else {
      this.selectedItemIndex = newIndex;
    }

    // Even with the wrap-around, this should naturally work when set to "false".
    this.scrollToSelectedItem(false);
  }

  // Has to be done or mouse-click on list items doesn't work.
  preventDropdownClose(event: MouseEvent) {
    event.preventDefault(); // Prevent `focusout` from interfering
  }

  onMouseSelect(event: MouseEvent, option: string) {
    event.stopPropagation(); // Prevent bubbling to document click
    this.selectedItemIndex = this.getItemInList(option);
    this.selectOption(); // Only valid options are clickable
  }

  private undoChanges() {
    if (this.value == this.initialValue) return;
    if (this.isDropdownOpen) this.isDropdownOpen = false;
    this.emitChangesToParent();
  }


  // When the component is very first entered, we want to know what
  // the initial value is (so undo is possible) and we assume that
  // to start, the 'parent' is aware of what the current value is.
  onFocusGained(event?: FocusEvent) {
    this.initialValue = this.value;
    this.parentValue = this.value;
    this.openDropdown();
  }

  onFocusLost(event?: FocusEvent) {
    if (this.limitToList) {
      if (!this.value) {
        // Do nothing with this...
      } else if (this.selectedItemIndex != -1) {
        this.value = this.sortedItems[this.selectedItemIndex].name;
      } else {
        this.value = '';
      }
    }
    if (this.isDropdownOpen) this.isDropdownOpen = false;
    this.emitChangesToParent();
  }

  openDropdown() {
    if (this.isDropdownOpen) return;
    this.toggleDropdown();
    this.selectedItemIndex = this.getItemInList(this.value);
    this.scrollToSelectedItem(true); 
  }

  closeDropdown(event?: FocusEvent) {

    const relatedTarget = event?.relatedTarget as HTMLElement;

    // Close only if focus is leaving the dropdown entirely
    if (!relatedTarget || !relatedTarget.closest('.dropdown-wrapper')) {
      this.isDropdownOpen = false;
    }
  }
}
