<app-widget-base [title]="'Organization Information'">
  <div class="widgetDetails">
    <div class="labelValue">
      <app-inputbox label="Field Office:" [id]="widgetName + '.inputbox.fieldoffice'" [value]="fieldOffice" [editable]="isEditMode"/>
    </div>
    <div class="labelValue">
      <app-inputbox label="Resident Agency:" [id]="widgetName + '.inputbox.residentagency'" [value]="residentAgency" [editable]="isEditMode"/>
    </div>
    <div class="labelValue">
      <app-inputbox label="Radio Call Sign:" [id]="widgetName + '.inputbox.radiocallsign'" [value]="hqCallSign" [editable]="isEditMode"/>
    </div>
  </div>
</app-widget-base>
