import { Component, Input, HostListener, ElementRef, ViewChild, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ValidatorComponent } from '../validator/validator.component';
import { InfoComponent } from '../info/info.component';
import { LabelComponent } from "../basic/label/label.component";

interface ListItem {
  id: number | string;
  name: string;
  sortOrder?: number;
}

@Component({
    selector: 'app-autocomplete-list',
    imports: [CommonModule, FormsModule, ValidatorComponent, LabelComponent, InfoComponent],
    templateUrl: './autocomplete-list.component.html',
    styleUrls: ['./autocomplete-list.component.scss']
})
export class AutocompleteListComponent implements OnChanges {
  @Input() items: ListItem[] = [];
  @Input() listHeight: string = '200px';
  @Input() placeholder: string = 'Begin typing';
  @Input() label: string = 'No Text Set';
  @Input() labelPosition: 'top' | 'left' = 'top';
  @Input() validationOptions: any = {};
  @Input() errors: string = '';
  @Input() validationGroup: string = '';
  @Input() id: string = '';
  @Input() manualFilter: boolean = true;
  @Output() inputChanged = new EventEmitter<string>();
  @Output() itemSelected = new EventEmitter<string | number>();

  isOpen: boolean = false;
  inputText: string = '';
  highlightedIndex: number = -1;

  @ViewChild('listContainer') listContainer!: ElementRef;

  ngOnInit() { }

  onInputChanged(event: any) {
    // Let the parent know that the value has changed.
    let inputValue = event.target.value;
    this.inputChanged.emit(inputValue);
  }

  onSelect(item: ListItem) {
    this.inputText = item.name;
    this.isOpen = false;
    this.highlightedIndex = -1;
    this.itemSelected.emit(item.id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      this.onItemsChanged();
    }
  }

  onItemsChanged() {
    if (this.items.length == 0) {
      if (this.isOpen) this.isOpen = false;
      return;
    }
    if (!this.isOpen) this.isOpen = true;
    this.highlightedIndex = 0;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    const inputElement = document.querySelector('.autocomplete-input') as HTMLElement;

    if (document.activeElement !== inputElement) {
      return;
    }

    if (!this.isOpen && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
      this.isOpen = true;
      return;
    }

    if (this.isOpen) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          this.highlightedIndex = (this.highlightedIndex + 1) % this.items.length;
          this.scrollToHighlighted();
          break;
        case 'ArrowUp':
          event.preventDefault();
          this.highlightedIndex = (this.highlightedIndex > 0 ? this.highlightedIndex : this.items.length) - 1;
          this.scrollToHighlighted();
          break;
        case 'Enter':
          event.preventDefault();
          this.selectHighlightedItem();
          break;
        case 'Tab':
          event.preventDefault();
          this.selectHighlightedItem();
          this.isOpen = false;
          this.moveToNextTabIndex();
          return;
      }
    }
  }

  private moveToNextTabIndex() {
    const currentFocusedElement = document.activeElement as HTMLElement;
    setTimeout(() => {
      const focusableElements = Array.from(
        document.querySelectorAll(`
        a[href], 
        area[href], 
        input:not([disabled]), 
        select:not([disabled]), 
        textarea:not([disabled]), 
        button:not([disabled]), 
        iframe, 
        object, 
        embed, 
        [contenteditable], 
        [tabindex]:not([tabindex="-1"])
      `)
      );
      const currentIndex = focusableElements.indexOf(currentFocusedElement);
      if (currentIndex !== -1 && currentIndex + 1 < focusableElements.length) {
        (focusableElements[currentIndex + 1] as HTMLElement).focus();
      }
    }, 0);
  }

  selectHighlightedItem() {
    if (this.highlightedIndex >= 0 && this.highlightedIndex < this.items.length) {
      this.onSelect(this.items[this.highlightedIndex]);
    } else {
      const matchingItem = this.items.find(item => item.name.toLowerCase() === this.inputText.toLowerCase());
      if (matchingItem) {
        this.onSelect(matchingItem);
      } else {
        this.inputText = '';
      }
    }
  }

  scrollToHighlighted() {
    if (this.listContainer && this.highlightedIndex >= 0) {
      const listItems = this.listContainer.nativeElement.querySelectorAll('li');
      const highlightedItem = listItems[this.highlightedIndex];

      if (highlightedItem) {
        highlightedItem.scrollIntoView({
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
  }

  @HostListener('document:click', ['$event'])
  closeAutocomplete(event: Event) {
    const target = event.target as HTMLElement;
    if (!target.closest('.autocomplete-container')) {
      this.isOpen = false;
    }
  }

}
