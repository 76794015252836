import { Component } from '@angular/core';

@Component({
  selector: 'app-stoplight-widget',
  imports: [],
  templateUrl: './stoplight-widget.component.html',
  styleUrl: './stoplight-widget.component.scss'
})
export class StoplightWidgetComponent {

}
