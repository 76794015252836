import { Component } from '@angular/core';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';

@Component({
  selector: 'app-skills-competencies-evaluations-widget',
  imports: [WidgetBaseComponent],
  templateUrl: './skills-competencies-evaluations-widget.component.html',
  styleUrl: './skills-competencies-evaluations-widget.component.scss'
})
export class SkillsCompetenciesEvaluationsWidgetComponent {

}
