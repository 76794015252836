import { Component } from '@angular/core';

@Component({
  selector: 'app-task-summaries',
  imports: [],
  templateUrl: './task-summaries.component.html',
  styleUrl: './task-summaries.component.scss'
})
export class TaskSummariesComponent {

}
