export const environment = {
  type: 'Development',
  title: 'PTS 2.0',
  version: 'v0.1 alpha',
  lastUpdate: '10/15/2024',
  msalConfig: {
    auth: {
      applicationId: 'b70a5c0e-4f0e-489a-8289-d508025f4a07',
      cloudInstance: 'https://login.microsoftonline.us',
      tenantId: '022914a9-b95f-4b7b-bace-551ce1a04071'
    },
    redirectUri: 'https://localhost'
  },
  graphApi: {
    scopes: ['User.Read', 'User.ReadBasic.All'],
    uri: 'https://graph.microsoft.us/v1.0/',
    pageSize: 100
  },
};