<div class="widgetColumn widgetColumnLeft">
  <div class="profile-picture">
    <img [src]="profilePhoto">
  </div>
  <app-user-profile-widget class="widget"/>
</div>

<div class="widgetColumn widgetColumnRight">
  <app-stoplight-widget />   
  <app-stoplight-widget />
  <app-stoplight-widget />
  <app-stoplight-widget />
  <app-stoplight-widget />
  <app-stoplight-widget />
  <app-stoplight-widget />
  <app-stoplight-widget />
  <app-stoplight-widget />
  <app-stoplight-widget />
</div>