import 'src/app/global.extensions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { UserAccountService } from './services/dataServices/user.account.service';
import { Guid } from './customTypes/Guid';
import { RootLayoutComponent } from "./components/layout/root-layout/root-layout.component";
import { ThemeService } from './services/theme.service';
import { LoadingOverlayComponent } from "./components/common/busy-overlay/busy-overlay.component";
import { ErrorOverlayComponent } from './components/common/error-overlay/error-overlay.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [
        CommonModule,
        RootLayoutComponent,
        ErrorOverlayComponent,
        LoadingOverlayComponent
    ]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'MSAL Test';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private userSvc: UserAccountService,
    private themes: ThemeService // Even though this isn't used, it needs to be instatiated.
  ) {}

  async ngOnInit() {
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  onClick() {
    console.log('Click!');
    var oneTimeSubscription: Subscription = this.userSvc.createAccount(new Guid()).subscribe((result: any) => {
      console.log('Create Account Success: ' + result.id);
      oneTimeSubscription.unsubscribe();
    });
  }
}
