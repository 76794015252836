import { Component } from '@angular/core';
import { SelectorTableComponent } from 'src/app/components/common/selector-table/selector-table.component';
import { AutocompleteListComponent } from "../../../../common/autocomplete-list/autocomplete-list.component";
import { InfoComponent } from "../../../../common/info/info.component";

interface ListItem {
  id: number | string;
  name: string;
  sortOrder?: number;
}

@Component({
    selector: 'app-permissions',
    imports: [SelectorTableComponent, AutocompleteListComponent, InfoComponent],
    templateUrl: './permissions.component.html',
    styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent {
  listHeight: string | undefined;
  fontSize: string | undefined;
  placeholder: string | undefined;
  label: string | undefined;
  errors: string | undefined;
  id: string | undefined;
  items: ListItem[] | undefined;
  width: string | undefined;

  onInputChanged($event: string) {
    //throw new Error('Method not implemented.');
  }
  onItemSelected($event: string|number) {
    //throw new Error('Method not implemented.');
  }
  columns = [
    { key: 'selected', label: 'Select', isCheckbox: true },
    { key: 'path', label: 'Name' },
    { key: 'create', label: 'Create', isCheckbox: true },
    { key: 'read', label: 'Read', isCheckbox: true },
    { key: 'update', label: 'Update', isCheckbox: true },
    { key: 'delete', label: 'Delete', isCheckbox: true },
    { key: 'own', label: 'Own Only', isCheckbox: true },
  ];

  permissions = [
    { id: '1', selected: false, path: '/user', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '2', selected: false, path: '/admin', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '1', selected: false, path: '/user', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '2', selected: false, path: '/admin', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '1', selected: false, path: '/user', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '2', selected: false, path: '/admin', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '1', selected: false, path: '/user', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '2', selected: false, path: '/admin', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '1', selected: false, path: '/user', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '2', selected: false, path: '/admin', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '1', selected: false, path: '/user', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '2', selected: false, path: '/admin', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '1', selected: false, path: '/user', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '2', selected: false, path: '/admin', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '1', selected: false, path: '/user', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '2', selected: false, path: '/admin', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '1', selected: false, path: '/user', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '2', selected: false, path: '/admin', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '1', selected: false, path: '/user', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '2', selected: false, path: '/admin', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '1', selected: false, path: '/user', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '2', selected: false, path: '/admin', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '1', selected: false, path: '/user', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '2', selected: false, path: '/admin', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '1', selected: false, path: '/user', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '2', selected: false, path: '/admin', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '1', selected: false, path: '/user', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '2', selected: false, path: '/admin', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '1', selected: false, path: '/user', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '2', selected: false, path: '/admin', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '1', selected: false, path: '/user', create: false, read: true, update: false, delete: false, ownOnly: false },
    { id: '2', selected: false, path: '/admin', create: false, read: true, update: false, delete: false, ownOnly: false }
  ];

  constructor() {
    this.permissions.sort((a, b) => a.path.localeCompare(b.path));
  }
}
