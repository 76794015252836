import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, NavigationStart, Router, RouterLink, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { BusyService } from 'src/app/services/busy.service';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
    imports: [CommonModule, RouterModule, RouterLink]
})
export class TabsComponent {
  @Input() tabs: { title: string; path: string }[] = []; // Update to accept path instead of content

  loading: boolean = false;
  private routerSubscription!: Subscription;

  constructor(private router: Router, private busy: BusyService) {}

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.busy.startBusy();
        console.log('Start')
      } else if (event instanceof NavigationEnd) {
        this.busy.stopBusy();
        console.log('Stop')
      }
    });
  }
  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe(); 
    }
  }

  get activeTabPath(): string {
    return window.location.pathname; // Get the current path
  }
}
