// busy.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BusyService {
  private busyCounter = 0;
  private busySubject = new BehaviorSubject<boolean>(false);
  isBusy$ = this.busySubject.asObservable();

  setBusy(isBusy: boolean): void {
    if (isBusy) {
      this.busyCounter++;
    } else if (this.busyCounter > 0) {
      this.busyCounter--;
      if (this.busyCounter < 0) this.busyCounter = 0;
    }

    // Update the busy state based on the counter
    this.busySubject.next(this.busyCounter > 0);
  }

  // Method to explicitly set busy
  startBusy(): void {
    this.setBusy(true);
  }

  // Method to explicitly stop busy
  stopBusy(): void {
    this.setBusy(false);
    this.busyCounter = 0;
  }
}
