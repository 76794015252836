import { Component } from '@angular/core';
import { DualListComponent } from 'src/app/components/common/basic/dual-list/dual-list.component';
import { AutocompleteListComponent } from 'src/app/components/common/autocomplete-list/autocomplete-list.component';
import { ValidatorComponent } from 'src/app/components/common/validator/validator.component';
import { SpacerComponent } from 'src/app/components/common/basic/spacer/spacer.component';
import { InfoComponent } from 'src/app/components/common/info/info.component';
import { AutocompleteActivedirectoryComponent } from "src/app/components/common/autocomplete-activedirectory/autocomplete-activedirectory.component";

interface ListItem {
  id: number | string;
  name: string;
  sortOrder?: number;
}

@Component({
    selector: 'app-accounts',
    imports: [AutocompleteActivedirectoryComponent, SpacerComponent],
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent {
  selectedAccountId: string = '';
  errors: string = '';
  items: ListItem[] = [
      { id: 1, name: 'Orbit' },
      { id: 2, name: 'Mirage' },
      { id: 3, name: 'Nimbus' },
      { id: 4, name: 'Ember' },
      { id: 5, name: 'Zenith' },
      { id: 6, name: 'Prism' },
      { id: 7, name: 'Cascade' },
      { id: 8, name: 'Valor' },
      { id: 9, name: 'Apex' },
      { id: 10, name: 'Glyph' },
      { id: 11, name: 'Haven' },
      { id: 12, name: 'Quasar' },
      { id: 13, name: 'Echo' },
      { id: 14, name: 'Solstice' },
      { id: 15, name: 'Zephyr' },
      { id: 16, name: 'Nexus' },
      { id: 17, name: 'Luminous' },
      { id: 18, name: 'Horizon' },
      { id: 19, name: 'Arcane' },
      { id: 20, name: 'Eclipse' }
    ];

  onValueChanged(id: string): void {
    this.selectedAccountId = id;
    console.log(id);
  }
}
