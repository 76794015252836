<div class="tabs">
  <ul class="tab-list">
    <li
      *ngFor="let tab of tabs"
      class="tab-item"
      [routerLink]="tab.title.trim() ? tab.path : null"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      <ng-container *ngIf="tab.title.trim(); else spacer">
        {{ tab.title }}
      </ng-container>
      <ng-template #spacer>
        <span class="spacer"></span>
      </ng-template>
    </li>
  </ul>
</div>
