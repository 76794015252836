<div class="inputs">
  <app-inputbox 
    [id]="'admin.roles.inputTest'"
    class="leftInput" 
    [label]="'Input:'" 
    [(value)]="role"
  />
  <app-inputbox 
    class="rightInput" 
    [label]="'Multiline:'" 
    [multiline]="true" 
    [(value)]="description" 
  />
</div>
<div class="otherControls">
  <app-dropdown 
    [id]="'admin.roles.dropdownTest'"
    [label]="'Dropdown:'" 
    [items]="items"
  />
</div>
<div class="outputs">
  <p>Role: {{ role }}</p>
  <p>Desc: {{ description }}</p>
</div>
