<div class="card ptsNoSelect">
  <div class="icon-container">
    <div class="profile-picture">
      <img 
        [src]="profilePhoto" 
        alt="{{ name }}" 
        class="card-img-top"
        (click)="profileClicked()"
      />
    </div>
    <div *ngIf="config.systemAlerts > 0" 
      class="badge badge-left ptsNoSelect" 
      (click)="alertClicked()"
      matTooltip="{{badgeLeftTooltip}}"
    >
      <span>!</span>
    </div>
    <div *ngIf="config.unreadMessages > 0" 
      class="badge badge-right ptsNoSelect" 
      (click)="messagesClicked()"
      matTooltip="{{badgeRightTooltip}}"
      >
      <span>{{ config.unreadMessages }}</span>
    </div>
  </div>
  <div class="card-body ptsNoSelect">
    <span class="card-title">{{ name }}</span>
  </div>
</div>
