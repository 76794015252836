// In data.service.ts
import { Injectable } from '@angular/core';
import { Guid } from 'src/app/customTypes/Guid';
import { UserAccount } from 'src/app/customTypes/dtos/userAccount';
import { from, Observable } from 'rxjs';
import { DataServiceBase } from './dataServiceBase';

@Injectable({
  providedIn: 'root'
})
export class UserAccountService extends DataServiceBase {

  public createAccount(id: Guid): Observable<any> {
    var payload = new UserAccount();
    payload.windowsId = id.toString();
    var promise = this.post('user/account', payload);
    return from(promise);
  }

  public getAccount(webId: string): Observable<any> {
    var promise = this.getById('user/account/', webId);
    return from(promise);
  }

}