import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    imports: [],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent {
  constructor(private router:Router){}
  leftText: string = environment.version;
  leftSubtext: string = ' (' + environment.lastUpdate + ')';
  rightText: string = 'Feedback?';
  rightTooltip: string = 'Click to send feedback, feature requests, bug reports.';

  releaseNotesClicked() {
    this.router.navigateByUrl('releasenotes');
  }

  feedbackClicked() {
    this.router.navigateByUrl('feedback');
  }

}
