import { Component } from '@angular/core';

@Component({
  selector: 'app-team-readiness',
  imports: [],
  templateUrl: './team-readiness.component.html',
  styleUrl: './team-readiness.component.scss'
})
export class TeamReadinessComponent {

}
