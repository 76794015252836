import { Component } from '@angular/core';
import { VerticalTabsComponent } from 'src/app/components/common/vertical-tabs/vertical-tabs.component';

@Component({
    selector: 'app-developer',
    imports: [VerticalTabsComponent],
    templateUrl: './developer.component.html',
    styleUrl: './developer.component.scss'
})
export class DeveloperComponent {
  tabs = [
    { title: 'Test Page', id: '', path: '/developer/componenttestpage' },
  ];
}
