import { Injectable } from '@angular/core';
import { LocalizationStrings } from '../globals/localizationStrings';
import { StringNotFoundException } from '../globals/errors';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  private _currentLanguage = 'EN';

  constructor() { }

  public get(key: string) : string {
    var lang = LocalizationStrings.Values[this._currentLanguage];
    if (!lang) throw new StringNotFoundException('The localized language key could not be found:' + this._currentLanguage);
    var result = lang[key];
    if (!result && this._currentLanguage !== 'EN') result=lang['EN'];
    if (!result) throw new StringNotFoundException('The localized language key could not be found:' + key);
    return result;
  }
}
