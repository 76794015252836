import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelComponent } from '../label/label.component';

interface ListItem {
  id: number | string;
  name: string;
  sortOrder?: number;
}

@Component({
    selector: 'app-dual-list',
    imports: [CommonModule, LabelComponent],
    templateUrl: './dual-list.component.html',
    styleUrls: ['./dual-list.component.scss']
})
export class DualListComponent implements OnInit {
  @Input() id: string = '';
  @Input() leftHeader: string = 'Available Items';
  @Input() rightHeader: string = 'Selected Items';
  @Input() leftList: ListItem[] = [];
  @Input() rightList: ListItem[] = [];
  @Input() multiSelect: boolean = false;
  @Input() stateName: string = 'state.name.unspecified';
  @Input() errors: string = '';

  selectedLeftItems: Set<ListItem> = new Set();
  selectedRightItems: Set<ListItem> = new Set();
  currentList: 'left' | 'right' = 'left';

  focusedLeftIndex: number | null = null;
  focusedRightIndex: number | null = null;
  isLeftListFocused: boolean = false;  // Track if left list has focus
  isRightListFocused: boolean = false; // Track if right list has focus
  lastSelectedItem: ListItem | null = null;

  constructor() { }

  ngOnInit() {
    this.leftList = this.sortList(this.leftList);
    this.rightList = this.sortList(this.rightList);
  }

  handleListFocus(list: 'left' | 'right') {
    if (list === 'left') {
      this.isLeftListFocused = true;
      this.isRightListFocused = false;
      if (this.focusedLeftIndex === null && this.leftList.length > 0) {
        this.focusedLeftIndex = 0;
      }
    } else {
      this.isRightListFocused = true;
      this.isLeftListFocused = false;
      if (this.focusedRightIndex === null && this.rightList.length > 0) {
        this.focusedRightIndex = 0;
      }
    }
  
    // Only remove the outline if multiSelect is true, allowing default browser outline otherwise
    const listContainer = document.querySelector(`.${list === 'left' ? 'left-list-container' : 'right-list-container'} .list-container`) as HTMLElement;
    if (this.multiSelect) {
      listContainer.style.outline = 'none'; // Remove focus outline for multi-select
    } else {
      listContainer.style.outline = ''; // Reset to default, letting CSS handle it
    }
  }
  
  

  handleListBlur(list: 'left' | 'right') {
    if (list === 'left') {
      this.isLeftListFocused = false;
    } else {
      this.isRightListFocused = false;
    }
  }

  handleListKeydown(event: KeyboardEvent, list: 'left' | 'right') {
    const itemList = list === 'left' ? this.leftList : this.rightList;
    let focusedIndex = list === 'left' ? this.focusedLeftIndex : this.focusedRightIndex;
  
    if (focusedIndex === null) {
      focusedIndex = 0; // Default to first item if none is focused
    }
  
    const isShiftPressed = event.shiftKey;
  
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (focusedIndex < itemList.length - 1) {
        focusedIndex++;
        if (isShiftPressed && this.multiSelect) {
          this.toggleItem(itemList[focusedIndex], list, event);
        }
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (focusedIndex > 0) {
        focusedIndex--;
        if (isShiftPressed && this.multiSelect) {
          this.toggleItem(itemList[focusedIndex], list, event);
        }
      }
    } else if (event.key === ' ') {
      event.preventDefault();
      const selectedItem = itemList[focusedIndex];
  
      if (isShiftPressed && this.multiSelect) {
        this.handleShiftSelect(selectedItem, list);
      } else {
        this.toggleItem(selectedItem, list, event); // Regular space behavior (toggle selection)
      }
    } else if (event.key === 'Escape') {
      event.preventDefault();
      this.clearSelections();
    } else if (event.ctrlKey && event.key === 'a') {
      event.preventDefault();
      this.selectAll(itemList);
    } else if (event.key === 'Enter') {
      event.preventDefault();
      // Handle Enter key to move selected items
      if (list === 'left') {
        this.moveToRight(this.toArray(this.selectedLeftItems));
      } else {
        this.moveToLeft(this.toArray(this.selectedRightItems));
      }
    }
  
    // Update the focused index
    if (list === 'left') {
      this.focusedLeftIndex = focusedIndex;
    } else {
      this.focusedRightIndex = focusedIndex;
    }
  
    // Scroll the focused item into view
    const listContainer = document.querySelector(`.${list === 'left' ? 'left-list-container' : 'right-list-container'} .list-container`) as HTMLElement;
    const listItems = listContainer.querySelectorAll('li');
    const focusedItem = listItems[focusedIndex] as HTMLElement;
  
    if (focusedItem) {
      focusedItem.scrollIntoView({ block: 'nearest' });
    }
  }
  
  
  
  handleShiftSelect(item: ListItem, list: 'left' | 'right') {
    const selectedItems = list === 'left' ? this.selectedLeftItems : this.selectedRightItems;
    const itemList = list === 'left' ? this.leftList : this.rightList;
  
    if (this.lastSelectedItem) {
      const lastIndex = itemList.indexOf(this.lastSelectedItem);
      const currentIndex = itemList.indexOf(item);
  
      // Select range between last selected item and currently focused item
      const range = lastIndex < currentIndex
        ? itemList.slice(lastIndex, currentIndex + 1)
        : itemList.slice(currentIndex, lastIndex + 1);
  
      range.forEach(itm => {
        if (selectedItems.has(itm)) {
          selectedItems.delete(itm); // Deselect if already selected
        } else {
          selectedItems.add(itm); // Select if not already selected
        }
      });
    } else {
      selectedItems.add(item); // If no last selected item, select the current one
    }
  
    this.lastSelectedItem = item; // Update the last selected item
  }
  


  moveToRight(items: ListItem[]) {
    const { from, to } = this.moveItems(items, this.leftList, this.rightList, this.selectedLeftItems);
    this.leftList = from;
    this.rightList = to;
  }

  moveToLeft(items: ListItem[]) {
    const { from, to } = this.moveItems(items, this.rightList, this.leftList, this.selectedRightItems);
    this.rightList = from;
    this.leftList = to;
  }

  moveItems(items: ListItem[], fromList: ListItem[], toList: ListItem[], selectedItems: Set<ListItem>) {
    const updatedFromList = fromList.filter(i => !items.includes(i));
    const updatedToList = this.sortList([...toList, ...items]);
    selectedItems.clear();
    return { from: updatedFromList, to: updatedToList };
  }

  sortList(list: ListItem[]): ListItem[] {
    return list.sort((a, b) => {
      const orderA = a.sortOrder ?? 0;
      const orderB = b.sortOrder ?? 0;
      return orderA !== orderB ? orderA - orderB : a.name.localeCompare(b.name);
    });
  }

  selectAll(list: ListItem[]) {
    const selectedItems = this.currentList === 'left' ? this.selectedLeftItems : this.selectedRightItems;
    list.forEach(item => selectedItems.add(item));
  }

  clearSelections() {
    this.currentList === 'left' ? this.selectedLeftItems.clear() : this.selectedRightItems.clear();
  }

  toArray(set: Set<ListItem>): ListItem[] {
    return Array.from(set);
  }

  handleDoubleClick(item: ListItem) {
    if (this.leftList.includes(item)) {
      this.moveToRight([item]);
    } else if (this.rightList.includes(item)) {
      this.moveToLeft([item]);
    }
  }

  toggleItem(item: ListItem, list: 'left' | 'right', event: MouseEvent | KeyboardEvent) {
    const selectedItems = list === 'left' ? this.selectedLeftItems : this.selectedRightItems;
    const itemList = list === 'left' ? this.leftList : this.rightList;

    // Find the correct list container from the event
    const listContainer = (event.currentTarget as HTMLElement).closest('.list-container') as HTMLElement;

    // Handle multi-select and shift-select logic
    if (this.multiSelect) {
      if (event instanceof MouseEvent && event.shiftKey && this.lastSelectedItem) {
        const lastIndex = itemList.indexOf(this.lastSelectedItem);
        const currentIndex = itemList.indexOf(item);
        const range = lastIndex < currentIndex
          ? itemList.slice(lastIndex, currentIndex + 1)
          : itemList.slice(currentIndex, lastIndex + 1);

        range.forEach(itm => {
          if (itm != this.lastSelectedItem) {
            if (selectedItems.has(itm)) {
              selectedItems.delete(itm);  // Remove if already selected
            } else {
              selectedItems.add(itm);  // Add if not already selected
            }
          }
        });
      } else {
        // Toggle selection for individual item
        selectedItems.has(item) ? selectedItems.delete(item) : selectedItems.add(item);
      }
    } else {
      selectedItems.clear();
      selectedItems.add(item);
    }


    // Set the last selected item and focus index
    this.lastSelectedItem = item;
    if (list === 'left') {
      this.focusedLeftIndex = itemList.indexOf(item);
      this.isLeftListFocused = true;
      this.isRightListFocused = false;
    } else {
      this.focusedRightIndex = itemList.indexOf(item);
      this.isRightListFocused = true;
      this.isLeftListFocused = false;
    }

    this.currentList = list;

    // Programmatically set focus on the correct list container after a click
    listContainer.focus();
  }

}
