import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UiConfiguration } from 'src/app/services/dataServices/uiService/uiConfiguration';
import { UiService } from 'src/app/services/dataServices/uiService/ui.service';

@Directive()
export abstract class BaseComponent implements OnInit, OnDestroy {
  config: UiConfiguration = new UiConfiguration();
  private subscriptions: Subscription[] = [];

  constructor(protected ui: UiService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.ui.uiConfig$.subscribe((config) => {
        this.config = config;
      })
    );
  }

  protected addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
