import { Component } from '@angular/core';

@Component({
  selector: 'app-team-roster',
  imports: [],
  templateUrl: './team-roster.component.html',
  styleUrl: './team-roster.component.scss'
})
export class TeamRosterComponent {

}
