import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutocompleteListComponent } from "../autocomplete-list/autocomplete-list.component";
import { ActiveDirectoryService, AdAccountFilterResponseRecordDto } from 'src/app/services/dataServices/activedirectory.service';
import { InfoComponent } from '../info/info.component';

interface ListItem {
  id: number | string;
  name: string;
  sortOrder?: number;
}

@Component({
    selector: 'app-autocomplete-activedirectory',
    imports: [AutocompleteListComponent],
    templateUrl: './autocomplete-activedirectory.component.html',
    styleUrl: './autocomplete-activedirectory.component.scss'
})
export class AutocompleteActivedirectoryComponent implements OnInit {
  @Input() items: ListItem[] = [];
  @Input() listHeight: string = '200px';
  @Input() width: string = '250px';
  @Input() height: string = '35px';
  @Input() fontSize: string = '14px';
  @Input() placeholder: string = 'Begin typing';
  @Input() label: string = 'No Text Set';
  @Input() labelPosition: 'top' | 'left' = 'top';
  @Input() id: string = '';
  @Input() validationOptions: any = {};
  @Input() errors: string = '';
  @Input() validationGroup: string = '';

  @Output() valueChange = new EventEmitter<string>();

  constructor(private ad: ActiveDirectoryService, private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void { }

  private _availableItems: AdAccountFilterResponseRecordDto[] = [];
  private _loading:boolean = false;
  private _lastFilter:string = '';
  private _lastLoadedFirstThreeCharacters:string = '';
  async onInputChanged(value: string) {
    // Don't pass in any items until the user's typed at least 3 letters.
    // This prevents particularly heavy API calls.
    value = value.trim().toLowerCase();
    this._lastFilter = value;
    if (this._loading) return;
    this._loading = true;

    if (!this.isValidFilter(value)) {
      this.items = [];
      this._availableItems = [];
      this.changeDetector.detectChanges();
      this._loading = false;
      return;
    }

    // If we've gotten this far and the available items are empty, try pulling them from the service.
    // Tracking the first 3 characters allows us to detect if the user has used the arrow key or mouse
    // to select/edit one of the first 3 characters, which would require a re-pull of the users from
    // the API.
    var firstThree = value.substring(0, 3);
    if (this._availableItems.length == 0 || firstThree != this._lastLoadedFirstThreeCharacters) {
      this._availableItems = await this.ad.getUsers(value);
      this._lastLoadedFirstThreeCharacters = firstThree;
    }

    // Filter out the items to show only those that match the filter.
    this.items = this.getFilteredItems();
    this.changeDetector.detectChanges();
    this._loading = false;
  }

  private isValidFilter(value: string): boolean {
    if (value.length < 3) return false;
    var filters = value.split(' ').filter(f => f.length > 0);
    var allFiltersAreSingleCharacter = true;
    filters.forEach(filter => {
      if (filter.length > 1) allFiltersAreSingleCharacter = false;
    });
    if (allFiltersAreSingleCharacter) return false;
    return true;
  }

  private getFilteredItems(): ListItem[] {
    const filters = this._lastFilter
      .split(' ')
      .map(f => f.toLowerCase())
      .filter(f => f.length > 0);
  
    return this._availableItems
      .filter(u =>
        filters.every(f =>
          (u.firstName && this.useStartsWithIfOnlyOneCharacter(u.firstName.toLowerCase(), f)) ||
          (u.middleName && this.useStartsWithIfOnlyOneCharacter(u.middleName.toLowerCase(), f)) ||
          (u.lastName && this.useStartsWithIfOnlyOneCharacter(u.lastName.toLowerCase(), f)) ||
          (u.email && this.useStartsWithIfOnlyOneCharacter(u.email.toLowerCase(), f))
        )
      )
      .map(u => ({
        id: u.webId,
        name: u.displayName,
        sortOrder: 0
      }));
  }
  
  private useStartsWithIfOnlyOneCharacter(value: string, filter: string): boolean {
    return filter.length === 1 ? value.startsWith(filter) : value.includes(filter);
  }
  

  async onItemSelected(value: string | number): Promise<void> {
    var id: string = '';
    if (typeof value === 'number') {
      id = value.toString();
    } else {
      id = value;
    }
    this.valueChange.emit(id);
  }

}
