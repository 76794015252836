import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiStateService {
  private readOnlyModeSubject = new BehaviorSubject<boolean>(false);
  private editingModeSubject = new BehaviorSubject<boolean>(false);
  private debugModeSubject = new BehaviorSubject<boolean>(false);

  isReadOnlyMode$ = this.readOnlyModeSubject.asObservable();
  isEditMode$ = this.editingModeSubject.asObservable();
  isDebugMode$ = this.debugModeSubject.asObservable();

  setReadOnlyMode(isReadOnly: boolean): void {
    this.readOnlyModeSubject.next(isReadOnly);
  }

  getReadOnlyMode(): boolean {
    return this.readOnlyModeSubject.value;
  }

  setEditMode(isEdit: boolean): void {
    this.editingModeSubject.next(isEdit);
  }

  getEditMode(): boolean {
    return this.editingModeSubject.value;
  }

  toggleEditMode(): void {
    this.editingModeSubject.next(!this.editingModeSubject.value);
  }

  setDebugMode(isDebug: boolean): void {
    this.debugModeSubject.next(isDebug);
  }

  getDebugMode(): boolean {
    return this.debugModeSubject.value;
  }

  toggleDebugMode(): void {
    this.debugModeSubject.next(!this.debugModeSubject.value);
  }
}
