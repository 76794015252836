import { LogLevel as MsalLogLevel } from '@azure/msal-browser';

export class LogLevel {
    public static readonly None = 0;
    public static readonly Debug = 1;
    public static readonly Info = 2;
    public static readonly Warning = 4;
    public static readonly Error = 8;
    public static readonly Trace = 16;
    public static readonly IsMsal = 32;

    public static parseMsal(level: MsalLogLevel): LogLevel {
        switch (level) {
            case MsalLogLevel.Verbose:
                return this.Debug | this.IsMsal;
            case MsalLogLevel.Info:
                return this.Info | this.IsMsal;
            case MsalLogLevel.Warning:
                return this.Warning | this.IsMsal;
            case MsalLogLevel.Error:
                return this.Error | this.IsMsal;
            case MsalLogLevel.Trace:
                return this.Trace | this.IsMsal;
        }
        return this.None;
    }
}