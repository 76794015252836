import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private _currentTheme = 'default';
  private _currentMode = 'light';

  constructor() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this._currentMode = 'dark';
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
      this._currentMode = e.matches ? "dark" : "light";
      this.setTheme(this._currentTheme);
    });
    this.setTheme(this._currentTheme);
   }

  public setTheme(key: string) : void {
    document.body.setAttribute('current-theme', key);
    document.body.setAttribute('current-theme-mode', key + '-' + this._currentMode);
  }

  public getTheme(): string {
    return this._currentTheme;
  }

  public getMode(): string {
    return this._currentMode;
  }
}
