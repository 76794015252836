<div class="info-container" *ngIf="id">
  <!-- Edit icon visible if global edit mode is enabled -->
  <div
    class="info-edit-icon"
    (click)="toggleEditMode()"
    *ngIf="isEditMode$ | async"
  ></div>

  <!-- Info icon visible if tooltip exists or global edit mode is enabled -->
  <div
    class="info-icon"
    *ngIf="info || (isEditMode$ | async)"
    (mouseenter)="onMouseEnter($event)"
    (mouseleave)="onMouseLeave()"
  ></div>
</div>

<!-- Modal window visible if local isEditing is true -->
<app-modal-overlay
  *ngIf="isEditing"
  class="info-edit-window"
  [id]="modalId.toString()"
>
  <div>
    <app-label [text]="'Enter the new tooltip:'" [editable]="false"></app-label>
    <textarea
      #friendlyInfoElement
      class="friendly-info"
      [textContent]="friendlyInfo"
    ></textarea>
    <button class="info-save" (click)="doSave(friendlyInfoElement.value)">
      Save
    </button>
  </div>
</app-modal-overlay>
