import { Directive, ElementRef, AfterViewInit, HostListener, OnDestroy, Renderer2, Input } from '@angular/core';
import { ModalService } from '../services/modal.service';

@Directive({
  selector: '[focusTrap]',
  standalone: true
})
export class FocusTrapDirective implements AfterViewInit, OnDestroy {
  @Input() focusTrapModal: boolean = false;

  private focusableElements: HTMLElement[] = [];
  private observer!: MutationObserver;
  private hiddenInput!: HTMLElement;  // Placeholder for the hidden input
  private ignoreNextFocusEvent = false; // Flag to temporarily ignore focus changes caused by mouse clicks

  constructor(private el: ElementRef, private renderer: Renderer2, private modal: ModalService) {}

  ngAfterViewInit() {
    this.addHiddenInput();
    this.updateFocusableElements();
    this.observeDOMChanges();
    this.setInitialFocus();
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }

    if (this.hiddenInput) {
      this.hiddenInput.remove();  // Clean up hidden input when directive is destroyed
    }
  }

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    // Only handle Tab key for focus trapping, allow arrow keys to be handled elsewhere
    if (event.key === 'Tab' && this.focusableElements.length > 0) {
      this.updateFocusableElements(); // Always update focusable elements

      const firstElement = this.focusableElements[0];
      const lastElement = this.getLastVisibleElement();
      const activeElement = document.activeElement as HTMLElement;
      const isShiftPressed = event.shiftKey;

      if (lastElement == null) return;

      // Handle Shift+Tab (backwards navigation)
      if (isShiftPressed) {
        if (activeElement === firstElement || activeElement === this.hiddenInput) {
          event.preventDefault();
          lastElement.focus();  // Move focus to the last element when Shift+Tab on the first or hidden input
        }
      } 
      // Handle Tab (forwards navigation)
      else {
        if (activeElement === lastElement || activeElement === this.hiddenInput) {
          event.preventDefault();
          firstElement.focus();  // Move focus to the first element when Tab on the last or hidden input
        }
      }
    }
  }

  @HostListener('document:mousedown', ['$event'])
  handleMouseDown(event: MouseEvent) {
    const target = event.target as HTMLElement;
    // If the click is outside the trap container, set the flag to ignore the next focus event
    if (!this.el.nativeElement.contains(target)) {
      this.ignoreNextFocusEvent = true;
    }
  }

  @HostListener('document:focusin', ['$event'])
  handleFocusIn(event: FocusEvent) {
    // Ignore this focus event if it was caused by a mouse click
    if (this.ignoreNextFocusEvent) {
      this.ignoreNextFocusEvent = false; // Reset the flag
      return;
    }

    // Disable this directive if the modal window is open.
    if (!this.focusTrapModal && this.modal.modalOpen) return;

    // If focus moves outside the component, trap it, but ignore focus changes within arrow key navigation
    const target = event.target as HTMLElement;
    if (target === this.hiddenInput || !this.el.nativeElement.contains(target)) {
      setTimeout(() => this.focusableElements[0]?.focus(), 0);  // Focus on the first focusable element
    }
  }

  private getFocusableElements(): HTMLElement[] {
    // Only query within the current component's subtree using el.nativeElement
    var ret = Array.from(
      this.el.nativeElement.querySelectorAll(
        'input, button, select, textarea, a[href], [tabindex]:not([tabindex="-1"])'
      ) as HTMLElement[]
    );
    ret = ret.filter(el => this.isElementVisible(el));
    return ret;
  }

  private isElementVisible(el: HTMLElement): boolean {
    const style = window.getComputedStyle(el);
    return style.display !== 'none' && style.visibility !== 'hidden';
  }

  private getLastVisibleElement(): HTMLElement | null {
    // Walk backwards through focusableElements to find the last one that's not disabled
    for (let i = this.focusableElements.length - 1; i >= 0; i--) {
      const element = this.focusableElements[i];
      if (!this.isDisabled(element) && element != this.hiddenInput) return element;
    }
    return null; // Return null if no valid element is found
  }

  // Helper method to check if an element is disabled
  private isDisabled(el: HTMLElement): boolean {
    return (el as HTMLInputElement).disabled === true || el.hasAttribute('disabled');
  }

  private updateFocusableElements() {
    this.focusableElements = this.getFocusableElements();
  }

  private observeDOMChanges() {
    this.observer = new MutationObserver(() => {
      this.updateFocusableElements();
    });

    this.observer.observe(this.el.nativeElement, {
      childList: true,
      subtree: true,
    });
  }

  private setInitialFocus() {
    setTimeout(() => {
      this.updateFocusableElements();
      if (this.focusableElements.length > 0) {
        this.focusableElements[0].focus();  
      }
    }, 100); // Ensure child elements are rendered
  }

  // Add a hidden, focusable input to trick the browser into maintaining the focus boundary
  private addHiddenInput() {
    this.hiddenInput = this.renderer.createElement('input');
    this.renderer.setAttribute(this.hiddenInput, 'type', 'text');
    this.renderer.setStyle(this.hiddenInput, 'position', 'absolute');
    this.renderer.setStyle(this.hiddenInput, 'left', '-9999px');
    this.renderer.setAttribute(this.hiddenInput, 'aria-hidden', 'true');

    // Append hidden input at the end of the component
    this.renderer.appendChild(this.el.nativeElement, this.hiddenInput);
  }
}
