<div class="dual-list-container">
  <div class="duallist-list left-list-container">
    <app-label class="ptsNoSelect" [text]="leftHeader" [id]="id + '.left'"></app-label>
    <div class="list-container" [attr.tabindex]="leftList.length ? '0' : '-1'"
         (focus)="handleListFocus('left')" (blur)="handleListBlur('left')" (keydown)="handleListKeydown($event, 'left')">
      <ul>
        <li *ngFor="let item of leftList; let i = index"
            class="ptsNoSelect"
            [class.selected]="selectedLeftItems.has(item)"
            [class.hasFocus]="multiSelect && i === focusedLeftIndex && isLeftListFocused"
            (click)="toggleItem(item, 'left', $event)"
            (dblclick)="handleDoubleClick(item)">
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>

  <div class="buttons">
    <button (click)="moveToRight(toArray(selectedLeftItems))" [disabled]="selectedLeftItems.size === 0">►</button>
    <button (click)="moveToLeft(toArray(selectedRightItems))" [disabled]="selectedRightItems.size === 0">◄</button>
  </div>

  <div class="duallist-list right-list-container">
    <app-label class="ptsNoSelect" [text]="rightHeader" [id]="id + '.right'"></app-label>
    <div class="list-container" [attr.tabindex]="rightList.length ? '0' : '-1'"
         (focus)="handleListFocus('right')" (blur)="handleListBlur('right')" (keydown)="handleListKeydown($event, 'right')">
      <ul>
        <li *ngFor="let item of rightList; let i = index"
            class="ptsNoSelect"
            [class.selected]="selectedRightItems.has(item)"
            [class.hasFocus]="multiSelect && i === focusedRightIndex && isRightListFocused"
            (click)="toggleItem(item, 'right', $event)"
            (dblclick)="handleDoubleClick(item)">
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</div>
