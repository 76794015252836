import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusyService } from 'src/app/services/busy.service';

@Component({
    selector: 'app-busy-overlay',
    imports: [CommonModule],
    templateUrl: './busy-overlay.component.html',
    styleUrl: './busy-overlay.component.scss'
})
export class LoadingOverlayComponent implements OnInit, OnDestroy {
  isBusy = false;

  constructor(private busyService: BusyService) {}

  ngOnInit(): void {
    this.subscriptions.push(this.busyService.isBusy$.subscribe((busy) => {
      this.isBusy = busy;
    }));
  }

  private subscriptions: Subscription[] = [];
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription=>{
      if(subscription) subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

}
