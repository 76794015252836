export class DateUtils {
  static nowUtc(): Date {
      const now = new Date();
      return new Date(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds(),
          now.getUTCMilliseconds()
      );
  }

  static timestamp(): string {
      const now = new Date();
      return now.getUTCFullYear().toString() +
          (now.getUTCMonth() + 1).toString().padStart(2, '0') +
          now.getUTCDate().toString().padStart(2, '0') + '.' +
          now.getUTCHours().toString().padStart(2, '0') +
          now.getUTCMinutes().toString().padStart(2, '0') +
          now.getUTCSeconds().toString().padStart(2, '0') + '.' +
          now.getUTCMilliseconds().toString().padStart(3, '0');
  }
}
