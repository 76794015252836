<div class="container">
  <div class="left-column">
    <div class="top-box">
      <app-autocomplete-activedirectory 
        class="test-input-box"
        [id]="'admin.accounts.selectAccount'" 
        [labelPosition]="'left'"
        [label]="'Select Account:'"
        [errors]="errors" 
        (valueChange)="onValueChanged($event)"
        [width]="'800px'"
        [listHeight]="'400px'"
        />
    </div>
    <div class="bottom-box">
      Bottom Box
      <app-spacer />
      Moar Bottom
    </div>
  </div>
  <div class="right-column">Right Box</div>
</div>