import { Injectable } from '@angular/core';
import { from, Observable, ReplaySubject } from 'rxjs';
import { DataServiceBase } from './dataServiceBase';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../token.service';
import { UiStateService } from './uiService/uiState.service';

interface InterfaceSetting {
  id: string;
  type: string;
  lang: string;
  value: string;
  oldValue: string;
}
interface InterfaceDto {
  id: string;
  type: string;
  lang: string;
  value: string;
}


@Injectable({
  providedIn: 'root'
})
export class InterfaceService extends DataServiceBase {
  private interfaces: InterfaceSetting[] = [];
  private interfaceSubject = new ReplaySubject<InterfaceSetting[]>(1);
  interface$ = this.interfaceSubject.asObservable();
  private lang = 'en';

  constructor(http: HttpClient, tokenService: TokenService, uiStateService: UiStateService) {
    super(http, tokenService, uiStateService);
  }

  // When the interface settings come in, the current value is stored in
  // 'oldValue' so the changes can later be tracked.
  protected override async initialize(): Promise<void> {
    this.interfaces = await this.get('ui/interface');
    this.interfaces.forEach(iface=>{
      iface.oldValue = iface.value;
    });
    this.triggerUpdateEvent();
  }

  // When the interface is requested by ID, just the current string value is
  // returned.
  public getInterface(id: string, type: string): string {
    if (this.interfaces.length == 0) return 'Loading';
    const inter = this.interfaces.find(t => 
      t.id === id && 
      t.type === type && 
      t.lang === this.lang);
    return inter ? inter.value : 'NF:[' + type + '/' + this.lang + '/' + id + ']';
  }

  // "Setting" an interface will not actually save it to the API unless explicitly
  // stated.
  public setInterface(id: string, type: string, value: string, save: boolean = false): void {
    var interIndex = this.interfaces.findIndex((i)=>
      i.id == id && 
      i.type == type && 
      i.lang == this.lang
    );
    var interfaceToSave!: InterfaceSetting;
    if (interIndex < 0) {
      // If the interface setting doesn't exist, create one.
      interfaceToSave = { id: id, type: type, lang: this.lang, value: value, oldValue: '' };
      this.interfaces.push(interfaceToSave);
    } else {
      // If it does exist, only the value can be changed.
      this.interfaces[interIndex].value = value;
      interfaceToSave = this.interfaces[interIndex];
    }
    // If this interface should be saved at the same time it's set, 
    // update the "old" value, and save it.
    if (save) this.saveInterface(interfaceToSave);
  }

  // Saves the interface to the API, but only if it's changed.
  private saveInterface(iface: InterfaceSetting): boolean {
    if (iface.value == iface.oldValue) return false;
    var iDto: InterfaceDto = {
      id: iface.id,
      type: iface.type,
      lang: this.lang,
      value: iface.value
    }
    this.put('ui/interface', iDto);
    // Because everything in JavaScript is byRef, we can update
    // the oldValue here and it will bubble up.
    iface.oldValue = iface.value;
    return true;
  }

  public saveInterfaces(): void {
    var interfacesChanged = false;
    this.interfaces.forEach(iface => {
      if (this.saveInterface(iface)) interfacesChanged = true;
    });
    this.triggerUpdateEvent();
  }

  public getAccount(webId: string): Observable<any> {
    var promise = this.getById('user/account/', webId);
    return from(promise);
  }

  public setLang(value: string) {
    this.lang = value;
    this.triggerUpdateEvent();
  }

  private triggerUpdateEvent() {
    // Use JSON to force a deep copy.
    this.interfaceSubject.next(JSON.parse(JSON.stringify(this.interfaces.filter(t=>t.lang == this.lang))));
  }
}
