<!-- This is effectively just a copy of the Label control but with a different font. Should this take over the use of the label component? -->

<div class="label-wrapper">
  <label class="label-text">{{ text }}</label>
  <!-- Show the edit icon only if global edit mode is enabled -->
  <div
    class="label-edit-icon"
    (click)="toggleModal()"
    *ngIf="isEditMode && id"
  ></div>
</div>

<!-- Show the modal only if isEditing is true -->
<app-modal-overlay
  *ngIf="isEditing"
  class="label-edit-window"
  [id]="modalId.toString()"
>
  <div>
    <app-label [text]="'Enter the new label:'" [editable]="false"></app-label>
    <textarea #textElement class="save-text" [textContent]="text"></textarea>
    <button class="label-save" (click)="doSave(textElement.value)">Save</button>
  </div>
</app-modal-overlay>
