import { CommonModule } from '@angular/common';
import { Component, Input, HostListener, Renderer2, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { UiService } from 'src/app/services/dataServices/uiService/ui.service';
import { UiConfiguration } from 'src/app/services/dataServices/uiService/uiConfiguration';
import { ModalOverlayComponent } from '../modal-overlay/modal-overlay.component';
import { LabelComponent } from "../basic/label/label.component";
import { Guid } from 'src/app/customTypes/Guid';
import { ModalService } from 'src/app/services/modal.service';
import { InterfaceService } from 'src/app/services/dataServices/interface.service';
import { Subscription } from 'rxjs';
import { UiStateService } from 'src/app/services/dataServices/uiService/uiState.service';

@Component({
    selector: 'app-info',
    imports: [CommonModule, ModalOverlayComponent, LabelComponent],
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {
  @Input() info!: string;
  @Input() id!: string;

  friendlyInfo = '';
  config: UiConfiguration = new UiConfiguration();
  modalId = Guid.newGuid();
  isEditing = false; // Local state for modal visibility
  isEditMode$ = this.uiStateService.isEditMode$; // Global edit mode state

  private subscriptions: Subscription[] = [];
  private tooltipElement: HTMLElement | null = null;

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private ui: UiService,
    private modal: ModalService,
    private interfaceSvc: InterfaceService,
    private uiStateService: UiStateService
  ) {}

  ngOnInit(): void {
    if (!this.id) return;

    this.subscriptions.push(
      this.modal.modalChanged$.subscribe((id) => {
        if (!this.modal.modalOpen && id === this.modalId.toString()) {
          this.isEditing = false; // Close modal window
        }
      })
    );

    this.subscriptions.push(
      this.interfaceSvc.interface$.subscribe(() => {
        this.updateTooltipInfo();
      })
    );

    this.subscriptions.push(
      this.ui.uiConfig$.subscribe((config) => (this.config = config))
    );
  }

  ngOnDestroy(): void {
    this.removeTooltip();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent): void {
    this.cdr.detectChanges();
    this.createTooltip(event);
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.removeTooltip();
  }

  private updateTooltipInfo(): void {
    var newInfo = this.interfaceSvc.getInterface(this.id, 'tooltip');
    if (newInfo.startsWith('NF:[')) newInfo = this.info;
    this.info = newInfo;
    if (newInfo) this.friendlyInfo = newInfo.replaceAll('<br>', '\n');
  }

  private createTooltip(event: MouseEvent): void {
    this.removeTooltip(); // Ensure no duplicate tooltips

    const infoIcon = event.target as HTMLElement;
    const iconRect = infoIcon.getBoundingClientRect();

    this.tooltipElement = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltipElement, 'info-popup');
    this.renderer.appendChild(
      this.tooltipElement,
      this.renderer.createText(this.friendlyInfo || this.info)
    );
    this.renderer.appendChild(document.body, this.tooltipElement);

    const { width: tooltipWidth, height: tooltipHeight } =
      this.tooltipElement!.getBoundingClientRect();
    const { innerWidth: viewportWidth, innerHeight: viewportHeight } = window;

    let top =
      iconRect.top + tooltipHeight + 10 > viewportHeight
        ? iconRect.top - tooltipHeight - 5
        : iconRect.top + iconRect.height + 5;

    const left =
      iconRect.left + tooltipWidth + 10 > viewportWidth
        ? iconRect.left - tooltipWidth - 5
        : iconRect.left + iconRect.width + 5;

    top -= 25;

    this.renderer.setStyle(this.tooltipElement, 'position', 'absolute');
    this.renderer.setStyle(this.tooltipElement, 'top', `${top}px`);
    this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);
    this.renderer.setStyle(this.tooltipElement, 'opacity', '1');
    this.renderer.setStyle(this.tooltipElement, 'visibility', 'visible');
  }

  private removeTooltip(): void {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
  }

  toggleEditMode(): void {
    if (!this.config.hasRole('Administrator')) return;
    this.isEditing = true; // Open modal window
  }

  doSave(value: string): void {
    this.isEditing = false; // Close modal window
    this.friendlyInfo = value;
    this.info = value.replaceAll('\n', '<br>');
    this.interfaceSvc.setInterface(this.id, 'tooltip', this.info, true); // Save info
  }
}
