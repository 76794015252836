import { Component } from '@angular/core';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
import { InputBoxComponent } from "../../common/basic/inputbox/inputbox.component";
import { TextboxComponent } from '../../common/basic/textbox/textbox.component';
import { AssignmentHistory } from './AssignmentHistory';
import { CommonModule } from '@angular/common';
import { DurationPipe } from 'src/app/pipes/duration.pipe';
import { LabelComponent } from '../../common/basic/label/label.component';

@Component({
  selector: 'app-assignment-history-widget',
  imports: [CommonModule, WidgetBaseComponent, InputBoxComponent, TextboxComponent, LabelComponent, DurationPipe],
  templateUrl: './assignment-history-widget.component.html',
  styleUrl: './assignment-history-widget.component.scss'
})
export class AssignmentHistoryWidgetComponent {
  widgetName: string = 'assignmentHistory';
  isEditMode: boolean = false;
  records: AssignmentHistory[] = [];
  totalDuration: String = '';

  ngOnInit() {
    this.records = AssignmentHistory.MockData;
    this.calculateTotalDuration();
  }



  calculateTotalDuration() {
    let totalDays = 0;
    let totalMonths = 0;
    let totalYears = 0;

    for (const record of this.records) {
      const start = new Date(record.startDate);
      const end = new Date(record.endDate);

      // Calculate differences
      let years = end.getFullYear() - start.getFullYear();
      let months = end.getMonth() - start.getMonth();
      let days = end.getDate() - start.getDate();

      // Adjust negative values
      if (days < 0) {
        const previousMonth = new Date(end.getFullYear(), end.getMonth(), 0);
        days += previousMonth.getDate();
        months--;
      }

      if (months < 0) {
        months += 12;
        years--;
      }

      // Add to totals
      totalDays += days;
      totalMonths += months;
      totalYears += years;
    }

    // Normalize totals (convert days and months to years and months)
    const daysInMonth = 30; // Approximation for normalization
    totalMonths += Math.floor(totalDays / daysInMonth);
    totalDays %= daysInMonth;
    totalYears += Math.floor(totalMonths / 12);
    totalMonths %= 12;

    // Format total duration
    this.totalDuration = `${totalYears}y, ${totalMonths}m, ${totalDays}d`;
  }


}
