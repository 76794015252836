import { Component } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { BodyComponent } from '../body/body.component';
import { FooterComponent } from '../footer/footer.component';

@Component({
    selector: 'app-root-layout',
    imports: [HeaderComponent, BodyComponent, FooterComponent],
    templateUrl: './root-layout.component.html',
    styleUrl: './root-layout.component.scss'
})
export class RootLayoutComponent {

}
