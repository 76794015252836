import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-spacer',
  standalone: true,
  template: '',
  styleUrls: ['./spacer.component.scss']
})
export class SpacerComponent {
  @Input() vertical: string = '10px'; 
  @Input() horizontal: string = '10px';

  @HostBinding('style.height') get height() {
    return this.vertical;
  }
  @HostBinding('style.width') get width() {
    return this.horizontal;
  }
}
