import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from '../token.service';
import { DataServiceBase } from './dataServiceBase';
import { UiStateService } from './uiService/uiState.service';

export class AdAccountFilterResponseRecordDto {
  constructor(user: any) {
    this.windowsId = user.windowsId;
    this.webId = user.webId;
    this.displayName = user.displayName;
    this.firstName = user.firstName;
    this.middleName = user.middleName;
    this.lastName = user.lastName;
    this.suffix = user.suffix;
    this.email = user.email;
    this.isContractor = user.isContractor;
    this.isDetailee = user.isDetailee;
    this.isTaskForceOfficer = user.isTaskForceOfficer;
    this.isOtherGovernmentAgency = user.isOtherGovernmentAgency;
    this.location = user.location;
    this.organization = user.organization;
    this.jobTitle = user.jobTitle;
    this.phonePrimary = user.phonePrimary;
    this.phoneMobile = user.phoneMobile;
    this.phoneBusiness = user.phoneBusiness;
  }
  windowsId: string;
  webId: string;
  displayName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  email: string;
  isContractor: boolean;
  isDetailee: boolean;
  isTaskForceOfficer: boolean;
  isOtherGovernmentAgency: boolean;
  location: string;
  organization: string;
  jobTitle: string;
  phonePrimary: string;
  phoneMobile: string;
  phoneBusiness: string[];
}

@Injectable({
  providedIn: 'root'
})
export class ActiveDirectoryService extends DataServiceBase {
  constructor(http: HttpClient, tokenService: TokenService, uiStateService: UiStateService) {
    super(http, tokenService, uiStateService);
  }

  public async getUsers(searchValue: string): Promise<AdAccountFilterResponseRecordDto[]> {
    const filter = { 'filter': searchValue };
    const result = await this.getWithParams('ui/adaccounts', filter);
    
    this.fixDisplayNames(result);
    return result.sort((a: AdAccountFilterResponseRecordDto, b: AdAccountFilterResponseRecordDto) => {
      return a.displayName.localeCompare(b.displayName);
    });
  }
  fixDisplayNames(users: AdAccountFilterResponseRecordDto[]): void {
    users.forEach(user => {
      var newName = [user.lastName, ', ', user.firstName];
      if (user.middleName) {
        newName.push(' ');
        newName.push(user.middleName);
        if (user.middleName.length == 1) {
          newName.push('.');
        } 
      }
      if (user.organization) {
        newName.push(' / ');
        newName.push(user.organization);
      }
      if (user.jobTitle) {
        newName.push(' / ');
        newName.push(user.jobTitle);
      }
      if (user.location) {
        newName.push(' / ');
        newName.push(user.location);
      }
      if (user.email) {
        newName.push(' (');
        newName.push(user.email);
        newName.push(')');
      }

      user.displayName = newName.join('');
    });
  }

}
