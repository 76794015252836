<app-widget-base [title]="'User Profile'">
  <div class="widgetDetails">
    <app-inputbox label="Bureau EOD:" [id]="widgetName + '.inputbox.bureau'" [value]="bureauEod" info="Entry on Duty (EOD) is the date you joined the Bureau." [editable]="isEditMode"/>
    <app-inputbox label="First Name:" [id]="widgetName + '.inputbox.firstname'" [value]="firstName" [editable]="isEditMode" />
    <app-inputbox label="Middle Name:" [id]="widgetName + '.inputbox.middlename'" [value]="middleName" [editable]="isEditMode"/>
    <app-inputbox label="Last Name:" [id]="widgetName + '.inputbox.lastname'" [value]="lastName" [editable]="isEditMode"/>
    <app-inputbox label="Suffix:" [id]="widgetName + '.inputbox.suffix'" [value]="suffix" [editable]="isEditMode"/>
    <app-inputbox label="Credentials:" [id]="widgetName + '.inputbox.fbicredentials'" [value]="credentials" [editable]="isEditMode"/>
    <app-checkbox [enabled]="false" [label]="'Official Passport:'" [value]="officialPassport" [id]="widgetName + '.checkbox.officialpassport'"/>
    <app-inputbox label="Passport Expiration:" [id]="widgetName + '.inputbox.passportexpiration'" [value]="passportExpiration" [editable]="isEditMode"/>
    <app-inputbox label="Mobile Phone:" [id]="widgetName + '.inputbox.mobilephone'" [value]="mobilePhone" [editable]="isEditMode"/>
    <app-inputbox label="Business Phone:" [id]="widgetName + '.inputbox.businessphone'" [value]="businessPhone" [editable]="isEditMode"/>
  </div>
</app-widget-base>
