import { Component } from '@angular/core';

@Component({
    selector: 'app-release-notes',
    imports: [],
    templateUrl: './release-notes.component.html',
    styleUrl: './release-notes.component.scss'
})
export class ReleaseNotesComponent {

}
