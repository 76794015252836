import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UiService } from 'src/app/services/dataServices/uiService/ui.service';
import { BaseComponent } from './BaseComponent';
import { AppStateService } from 'src/app/services/dataServices/appState.service';

@Directive()
export abstract class StatefulComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() id: any;
  @Input() value: any;
  @Input() enabled: boolean = true;
  @Output() valueChange = new EventEmitter<boolean>();

  private internalChange = false; // Guard to track internal changes

  constructor(protected override ui: UiService, protected app: AppStateService) {
    super(ui);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (!this.id) {
      console.error('Every component must have an ID');
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['value']) return;

    const previousValue = changes['value'].previousValue;
    const currentValue = changes['value'].currentValue;

    // Prevent processing if this change originated internally
    if (this.internalChange) {
      this.internalChange = false;
      return;
    }

    if (previousValue === currentValue) return;

    this.app.setState(this.id, currentValue);
  }

  onValueChange(newValue: any): void {
    if (newValue instanceof HTMLElement) {
      // Handle HTML elements
      if (newValue.tagName === 'INPUT') {
        const inputElement = newValue as HTMLInputElement;
        switch (inputElement.type) {
          case 'checkbox':
            this.value = inputElement.checked;
            break;
          case 'number':
            this.value = inputElement.valueAsNumber;
            break;
          default:
            this.value = inputElement.value;
        }
      } else if (newValue.tagName === 'TEXTAREA') {
        const textareaElement = newValue as HTMLTextAreaElement;
        this.value = textareaElement.value;
      } else {
        console.warn(`Unhandled HTML element type: ${newValue.tagName}`);
      }
    } else if (typeof newValue === 'string') {
      this.value = newValue;
    } else if (typeof newValue === 'number') {
      this.value = newValue;
    } else if (typeof newValue === 'boolean') {
      this.value = newValue;
    } else {
      console.warn('Unhandled value type:', typeof newValue, newValue);
      this.value = newValue;
    }

    const previousValue = this.app.getState(this.id);
    const currentValue = this.value;
    if (previousValue === currentValue) return;

    // Mark this change as internal to prevent `ngOnChanges` from processing it
    this.internalChange = true;

    // Update app state and emit the new value
    this.app.setState(this.id, this.value);
    this.valueChange.emit(this.value);
  }
}
