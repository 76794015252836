<app-widget-base [title]="'Evidence Response Program History'">

  <app-textbox 
  text="This is a test value. This should be able to wrap text appropriately when the window is resized, or if the text is too long to fit in the container." 
  [id]="widgetName + '.textbox.test'" />

  <div>Total: {{totalDuration}}</div>

  <div *ngFor = 'let record of records' class="widgetRecord">
    <div class="record">
      <div class="assignment">
        <div class="details">
          <app-inputbox 
            [value]="record.officeNameLong" 
            label="Office:" 
            [id]="widgetName + '.inputbox.office'"
            [editable]="isEditMode"
          />
          <app-inputbox 
            [value]="record.teamNameLong" 
            label="Team:" 
            [id]="widgetName + '.inputbox.team'"
            [editable]="isEditMode"
          />
          <app-inputbox 
            [value]="record.positionNameLong" 
            label="Position:" 
            [id]="widgetName + '.inputbox.position'"
            [editable]="isEditMode"
          />
          <app-inputbox 
            [value]="record.roleNameLong" 
            label="Role:" 
            [id]="widgetName + '.inputbox.role'"
            [editable]="isEditMode"
          />
        </div>
        <div class="dates">
          <app-inputbox 
            [value]="record.startDate | date:'MM/dd/yyyy'"
            label="Start:" 
            [id]="widgetName + '.inputbox.startDate'"
            [editable]="isEditMode"
          />
          <app-inputbox 
            [value]="record.endDate | date:'MM/dd/yyyy'"
            label="End:" 
            [id]="widgetName + '.inputbox.endDate'"
            [editable]="isEditMode"
          />
          <div class="duration">
            <app-label text="Duration: " [id]="widgetName + '.textbox.duration'"/>: {{ record.startDate | duration:record.endDate }}
          </div>
        </div>  
      </div>

      <div class="subdetails">
        <div class="buttons">
          <div class="appButton editIcon"></div>
          <div class="appButton deleteIcon"></div>
        </div>
      </div>

    </div>
  </div>
  
<div class="appButton addButton"></div>

</app-widget-base>
