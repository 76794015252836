import { Component } from '@angular/core';
import { InputBoxComponent } from '../../common/basic/inputbox/inputbox.component';
import { CheckboxComponent } from '../../common/basic/checkbox/checkbox.component';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';

@Component({
    selector: 'app-user-profile-widget',
    imports: [
      WidgetBaseComponent,
      InputBoxComponent,
      CheckboxComponent
    ],
    templateUrl: './user-profile-widget.component.html',
    styleUrl: './user-profile-widget.component.scss'
})
export class UserProfileWidgetComponent {
  widgetName: string = 'personnelInformation';
  bureauEod: string = '01/01/2001';
  firstName: string = 'Jonathan';
  middleName: string = 'Barnum';
  lastName: string = 'Smith';
  suffix: string = 'Jr.';
  mobilePhone: string ='123-456-7890';
  businessPhone: string = '123-456-7890, 123-456-7890';
  officialPassport: boolean = true;
  passportExpiration: string = '01/01/2001';
  credentials: string = '123456';

  isEditMode: boolean = false;
}