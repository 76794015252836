import { Component } from '@angular/core';
import { LabelComponent } from '../../common/basic/label/label.component';
import { CheckboxComponent } from '../../common/basic/checkbox/checkbox.component';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
import { InputBoxComponent } from '../../common/basic/inputbox/inputbox.component';

@Component({
  selector: 'app-organization-information-widget',
  imports: [WidgetBaseComponent, InputBoxComponent],
  templateUrl: './organization-information-widget.component.html',
  styleUrl: './organization-information-widget.component.scss'
})
export class OrganizationInformationWidgetComponent {
  widgetName: string = "organizationInformation";

  fieldOffice: string = 'San Francisco';
  residentAgency: string = 'San Francisco HQ';
  hqCallSign: string = 'AB-123';

  isEditMode: boolean = false;
}
