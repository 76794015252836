<app-modal-overlay [id]="modalId.toString()">
  <div class="color-edit-wrapper">
    <div class="color-edit-window">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Value</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let iface of interfaces">
            <td>{{ iface.id }}</td>
            <td>
              <input [(ngModel)]="iface.value" (ngModelChange)="onInputChange(iface)" placeholder="Enter value" />
            </td>
            <td>
              <div class="color-box" [style.backgroundColor]="iface.value"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <button class="color-edit-save" (click)="saveSettings()">Save</button>
  </div>
</app-modal-overlay>
