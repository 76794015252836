import { Component } from '@angular/core';
import { InputBoxComponent } from "../../../../common/basic/inputbox/inputbox.component";
import { DropdownComponent } from 'src/app/components/common/basic/dropdown/dropdown.component';

interface ListItem {
  id: number | string;
  name: string;
  sortOrder?: number;
}

@Component({
    selector: 'app-roles',
    imports: [InputBoxComponent, DropdownComponent],
    templateUrl: './roles.component.html',
    styleUrl: './roles.component.scss'
})
export class RolesComponent {
  role: string = '';
  description: string = '';
  items: ListItem[] = [
    { id: 1, name: 'Orbit' },
    { id: 2, name: 'Mirage' },
    { id: 3, name: 'Nimbus' },
    { id: 4, name: 'Ember' },
    { id: 5, name: 'Zenith' },
    { id: 6, name: 'Prism' },
    { id: 7, name: 'Cascade' },
    { id: 8, name: 'Valor' },
    { id: 9, name: 'Apex' },
    { id: 10, name: 'Glyph' },
    { id: 11, name: 'Haven' },
    { id: 12, name: 'Quasar' },
    { id: 13, name: 'Echo' },
    { id: 14, name: 'Solstice' },
    { id: 15, name: 'Zephyr' },
    { id: 16, name: 'Nexus' },
    { id: 17, name: 'Luminous' },
    { id: 18, name: 'Horizon' },
    { id: 19, name: 'Arcane' },
    { id: 20, name: 'Eclipse' }
  ];



}
