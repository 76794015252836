import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StatefulComponent } from '../../StatefulComponent';
import { LabelComponent } from '../label/label.component';
import { ValidatorComponent } from '../../validator/validator.component';
import { InfoComponent } from '../../info/info.component';

@Component({
    selector: 'app-inputbox',
    templateUrl: './inputbox.component.html',
    styleUrls: ['./inputbox.component.scss'],
    imports: [FormsModule, CommonModule, LabelComponent, ValidatorComponent, InfoComponent]
})
export class InputBoxComponent extends StatefulComponent {
  @Input() label: string = 'Label:';
  @Input() multiline: boolean = false;
  @Input() placeholder: string = 'Enter Text';
  @Input() validationOptions: any = {};
  @Input() errors: string = '';
  @Input() validationGroup: string = '';
  @Input() labelWidth: string = '10px';
  @Input() editable: boolean = true;
  @Input() info!: string;
}
