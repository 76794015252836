import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BaseballCardComponent } from '../baseball-card/baseball-card.component';
import { LocalizationService } from '../../../services/localization.service';
import { HeaderSearchBarComponent } from "./header-search-bar/header-search-bar.component";
import { CommonModule } from '@angular/common';
import { UiConfiguration } from 'src/app/services/dataServices/uiService/uiConfiguration';
import { UiService } from 'src/app/services/dataServices/uiService/ui.service';
import { Guid } from 'src/app/customTypes/Guid';
import { ColorsComponent } from "../pages/admin/colors/colors.component";
import { ModalService } from 'src/app/services/modal.service';
import { Subscription } from 'rxjs';
import { AutocompleteActivedirectoryComponent } from "../../common/autocomplete-activedirectory/autocomplete-activedirectory.component";
import { UiStateService } from 'src/app/services/dataServices/uiService/uiState.service';

interface ListItem {
  id: number | string;
  name: string;
  sortOrder?: number;
}

@Component({
    selector: 'app-header',
    imports: [BaseballCardComponent, HeaderSearchBarComponent, CommonModule, ColorsComponent, AutocompleteActivedirectoryComponent],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() value: string = '';
  items: ListItem[] = [
    { id: 1, name: 'Administrator', sortOrder: 0 },
    { id: 2, name: 'Moderator', sortOrder: 1 },
    { id: 3, name: 'ElevatedUser', sortOrder: 2 },
    { id: 4, name: 'BaseUser', sortOrder: 3 }
  ];
  titleAbbrv: string = '';
  title: string = '';
  config: UiConfiguration = new UiConfiguration();
  isEditingColors: boolean = false;
  colorModalId: Guid = new Guid();
  private subscriptions: Subscription[] = [];

  constructor(
    private str: LocalizationService, 
    private ui: UiService, 
    private modal: ModalService,
    public uiStateService: UiStateService
  ) { }

  ngOnInit(): void {
    this.titleAbbrv = this.str.get('TitleAbbreviation');
    this.title = this.str.get('AppTitle');
    this.subscriptions.push(this.ui.uiConfig$.subscribe((config) => {
      this.config = config;
    }));
    this.subscriptions.push(this.modal.modalChanged$.subscribe((id) => {
      if (!this.modal.modalOpen && id == this.colorModalId.toString()) this.isEditingColors = false;
    }));
  }

  onSwitchUser(targetUser: string): void {
    //this.ui.setConfiguration(targetUser);
    this.uiStateService.setReadOnlyMode(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      if (subscription) subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  toggleEditMode() {
    this.uiStateService.toggleEditMode();
  }

  toggleDebugMode() {
    this.uiStateService.toggleDebugMode();
  }

  toggleColorMode() {
    this.isEditingColors = !this.isEditingColors;
  }
}
