import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'; // Import RouterModule
import { FocusTrapDirective } from 'src/app/directives/focus-trap.directive';
import { InfoComponent } from '../info/info.component';

@Component({
    selector: 'app-vertical-tabs',
    templateUrl: './vertical-tabs.component.html',
    styleUrls: ['./vertical-tabs.component.scss'],
    imports: [CommonModule, RouterModule, FocusTrapDirective, InfoComponent]
})
export class VerticalTabsComponent {
  @Input() tabs: { title: string; id: string, path: string }[] = [];
}
