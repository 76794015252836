import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor(private msalService: MsalService) {}

  private isInitialized: boolean = false;

  async initialize(): Promise<void> {
    if (this.isInitialized) return;

    // Initialize MSAL and handle redirect promise
    await this.msalService.instance.initialize();
    const authResponse = await this.msalService.instance.handleRedirectPromise();

    // Set active account if available from redirect response
    if (authResponse?.account) {
      this.msalService.instance.setActiveAccount(authResponse.account);
    }

    this.isInitialized = true;
  }

  async getToken(accessToken: boolean = false): Promise<string> {
    await this.initialize();

    const scopes: string[] = ['openid', 'profile', 'User.Read'];

    try {
      // Check for an active account
      let account = this.msalService.instance.getActiveAccount();
      if (!account) {
        // No active account found; attempt an interactive login
        await this.msalService.loginRedirect({ scopes });
        return ''; // Exit here; the redirect will handle the rest
      }

      // Attempt silent token acquisition
      const result = await firstValueFrom(this.msalService.acquireTokenSilent({ 
        account, 
        scopes 
      }));

      if (result && result.idToken) {
        if (accessToken) return result.accessToken;
        return result.idToken;
      } else {
        throw new Error('Silent token acquisition returned undefined.');
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Silent acquisition failed; fallback to an interactive login
        await this.msalService.acquireTokenRedirect({ scopes });
        return ''; // Exit here; the redirect will handle the rest
      } else {
        throw error; // Rethrow any other errors
      }
    }
  }

  async getClaims(): Promise<any> {
    const parts = (await this.getToken()).split('.');
    if (parts.length !== 3) {
      throw new Error('JWT is not in the correct format');
    }
    const payload = parts[1];
    const decodedPayload = JSON.parse(atob(payload.replace(/-/g, '+').replace(/_/g, '/')));
    return decodedPayload;
  }

  async getOptions(includeContentType: boolean = true, responseType: 'json' | 'text' = 'json'): Promise<any> {
    const options = {
      headers: {
        'Accept': responseType === 'json' ? 'application/json' : 'text/plain'
      },
      responseType: responseType
    } as const;

    if (includeContentType) {
      (options.headers as any)['Content-Type'] = 'application/json';
    }

    const token = await this.getToken();
    if (token) {
      (options.headers as any).Authorization = 'Bearer ' + token;
    }

    return options;
  }
}
