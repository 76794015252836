export class Guid {
  static Empty: Guid = new Guid(new Uint8Array(16)); 
  private bytes: Uint8Array;
  private value: string = '';
  private intValue: BigInt = BigInt(-1);

  constructor(value?: string | Uint8Array) { 
    if (typeof value === 'string' && value !== '') {
      const byteValue = Guid.stringToBytes(value);
      if (!Guid.validate(byteValue)) throw new Error('Invalid Guid: ' + value);
      this.bytes = byteValue;
    } else if (value instanceof Uint8Array && value.length === 16) {
      if (!Guid.validate(value)) throw new Error('Invalid Guid: byte array is not valid');
      this.bytes = value;
    } else {
      this.bytes = Guid.newGuid().bytes; 
    }
    this.value = Guid.bytesToString(this.bytes);
    this.intValue = Guid.bytesToBigInt(this.bytes);
  }

  // Use Symbol.toPrimitive to define how the object is coerced to strings or numbers.
  [Symbol.toPrimitive](hint: string) {
    if (hint === 'string' || hint === 'default') {
      return this.value;
    }
    return this.toBigInt();
  }

  toBigInt(): BigInt {
    return this.intValue;
  }

  toString(): string {
    return this.value;
  }

  equals(guid: Guid | string | BigInt): boolean {
    if (typeof guid === 'string') return guid === this.value;
    if (guid instanceof Guid) return this.bytes.every((byte, index) => byte === guid.bytes[index]);
    if (typeof guid === 'bigint') return this.intValue === guid;
    return false;
  }
  

  static newGuid(): Guid {
    const newGuidBytes = Guid.generate();
    return new Guid(newGuidBytes);
  }

  private static generate(): Uint8Array {
    const array = new Uint8Array(16);
    crypto.getRandomValues(array);
    
    // Set version (4) and variant bits
    array[6] = (array[6] & 0x0f) | 0x40; // Version 4 UUID
    array[8] = (array[8] & 0x3f) | 0x80; // Variant 1 (10xx)

    return array;
  }

  private static validate(bytes: Uint8Array): boolean {
    if (!(bytes instanceof Uint8Array) || bytes.length !== 16) {
      return false;
    }

    // Check if the GUID is the empty GUID (all zeroes)
    const isEmpty = bytes.every(byte => byte === 0);
    if (isEmpty) {
      return true; // Valid as the empty GUID
    }

    // Validate version (UUIDv4 - version 4)
    const version = (bytes[6] >> 4) & 0x0f;
    if (version !== 4) {
      return false;
    }

    // Validate variant (should be 10xx for RFC 4122 compliance)
    const variant = (bytes[8] >> 6) & 0x03;
    if (variant !== 0b10) {
      return false;
    }

    return true;
  }

  private static stringToBytes(guid: string): Uint8Array {
    const bytes = new Uint8Array(16);
    const hexParts = guid.replace(/-/g, '').match(/.{1,2}/g) || [];
    hexParts.forEach((hex, index) => {
      bytes[index] = parseInt(hex, 16);
    });
    return bytes;
  }

  private static bytesToBigInt(byteArray: Uint8Array): BigInt {
    let result = BigInt(0);
    for (let i = 0; i < byteArray.length; i++) {
      result = (result << BigInt(8)) + BigInt(byteArray[i]);
    }
    return result;
  }

  private static bytesToString(bytes: Uint8Array): string {
    return [...bytes]
      .map((b, i) => b.toString(16).padStart(2, '0'))
      .join('')
      .replace(/^(.{8})(.{4})(.{4})(.{4})(.{12})$/, '$1-$2-$3-$4-$5');
  }
}
