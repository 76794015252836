import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ErrorService } from 'src/app/services/error.service';
import { Subscription } from 'rxjs';

interface ErrorInfo {
  message: string;
  innerMessage: string;
  stack?: string;
}

@Component({
    selector: 'app-error-overlay',
    imports: [CommonModule],
    templateUrl: './error-overlay.component.html',
    styleUrl: './error-overlay.component.scss'
})
export class ErrorOverlayComponent implements OnInit, OnDestroy {
  isErrored = false;
  message: SafeHtml = ''; 

  constructor(
    private errorService: ErrorService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(this.errorService.newError$.subscribe((errList) => {
      if (errList.length == 0) return;
      this.isErrored = true;
      this.parseMessage(errList);
    }));
  }

  private subscriptions: Subscription[] = [];
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription=>{
      if(subscription) subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  private parseMessage(errors: ErrorInfo[]) {
    var value = '';
    errors.forEach(error => {
      value = value 
      + '<div class="errorTitle" style="font-weight: 500;">'
      + error.message 
      + '</div><div class="errorMessage" style="font-weight: bold; font-size: 15px;">' 
      + error.innerMessage 
      + '</div>' 
      + this.replaceLineBreaks(error.stack);
      + '<p>';
    });

    this.message = this.sanitizer.bypassSecurityTrustHtml(value);

  }

  private replaceLineBreaks(text: string | undefined): string {
    if (!text) return '';
    var ret = text.replace(/(\r\n|\n|\r)/g, '<br> &nbsp;');
    ret = ret.substring(ret.indexOf('<br>') + 4);
    return ret;
  }
}
