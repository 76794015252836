import { Component } from '@angular/core';
import { SearchBarComponent } from "../../../common/search-bar/search-bar.component";

@Component({
    selector: 'app-header-search-bar',
    imports: [SearchBarComponent],
    templateUrl: './header-search-bar.component.html',
    styleUrl: './header-search-bar.component.scss'
})
export class HeaderSearchBarComponent {

}
