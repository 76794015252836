<div class="shared-parent-container">
  <div class="horizontal-container">
    <app-label [id]="id" [text]="label"></app-label>
    <div class="autocomplete-wrapper">
      <app-validator [errors]="errors" [group]="validationGroup" [options]="validationOptions">
        <input type="text" class="autocomplete-input"
          [(ngModel)]="inputText"
          (input)="onInputChanged($event)" [placeholder]="placeholder">
      </app-validator>
      <div *ngIf="isOpen" class="autocomplete-list" [ngStyle]="{'max-height': listHeight}">
        <ul #listContainer>
          <li *ngFor="let item of items; let i = index" [ngClass]="{'highlighted': i === highlightedIndex, 'hoverable': true}"
            (click)="onSelect(item)">
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <app-info [id]="id" />
  </div>
</div>
