<div class="vertical-tabs">
  <ul class="tab-list">
    <li
      *ngFor="let tab of tabs"
      [routerLink]="tab.path"
      routerLinkActive="active"
      class="tab-item"
    >
      <span class="tab-label">{{ tab.title }}</span>
      <app-info [id]="tab.id"></app-info>
    </li>
  </ul>
  <div class="tab-content-wrapper">
    <div class="tab-content" focusTrap>
      <router-outlet />
    </div>
  </div>
</div>
