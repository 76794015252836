import { CommonModule } from '@angular/common';
import { Component, Input, HostListener, ElementRef, Renderer2, ChangeDetectorRef, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
    selector: 'app-validator',
    templateUrl: './validator.component.html',
    styleUrls: ['./validator.component.scss'],
    imports: [CommonModule]
})
export class ValidatorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() errors: string = '';
  @Input() value: string = '';
  @Input() options: any = {};
  @Input() group: string = 'default';

  message: string = '';
  hasFocus: boolean = false;

  // Track the index for this validator in the validation service
  private validatorIndex: number = -1;

  // Timeout for debounced validation
  private validationTimeout: any;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private validationService: ValidationService
  ) {}

  ngOnInit(): void {
    // Register with the validation service and store the index
    this.validatorIndex = this.validationService.registerValidator(this.group);
    this.doValidation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Re-run validation when value or options change
    if (changes['value'] || changes['options']) {
      this.doValidation();
    }
    if (changes['errors']) this.validate();
  }

  ngOnDestroy(): void {
    // Unregister the validator on component destruction
    if (this.validatorIndex >= 0) {
      this.validationService.unregisterValidator(this.validatorIndex, this.group);
    }
  }

  get isValid(): boolean {
    return !this.message; // Valid if no message exists
  }

  private doValidation(): void {
    clearTimeout(this.validationTimeout);

    // Debounce validation to improve performance on frequent input changes
    this.validationTimeout = setTimeout(() => {
      this.validate();
      const hasError = this.message !== '';
      this.validationService.updateValidator(this.validatorIndex, hasError, this.group);
    }, 200); // Adjust delay as needed
  }

  private validate(): void {
    this.message = '';
    this.addMessage(this.errors);

    // Skip other validations if value is empty and not required
    if (!this.value && !this.options.required) return;

    if (this.options.required) this.validateRequired();
    if (this.options.minLength) this.validateMinLength();
    if (this.options.maxLength) this.validateMaxLength();
    if (this.options.alphaOnly) this.validateAlphaOnly();
    if (this.options.numericOnly) this.validateNumericOnly();
    if (this.options.alphaNumeric) this.validateAlphaNumeric();
  }

  private validateRequired(): void {
    if (this.value) return;
    this.addMessage('This value is required.');
  }

  private validateMinLength(): void {
    const minLength = Number(this.options.minLength) || -1;
    if (minLength < 0) return;
    if (this.value.length >= minLength) return;
    this.addMessage(`This value must be at least ${minLength} characters.`);
  }

  private validateMaxLength(): void {
    const maxLength = Number(this.options.maxLength) || -1;
    if (maxLength < 0) return;
    if (this.value.length <= maxLength) return;
    this.addMessage(`This value must not be longer than ${maxLength} characters.`);
  }

  private validateAlphaOnly(): void {
    const alphaRegex = /^[a-zA-Z]+$/;
    if (alphaRegex.test(this.value)) return;
    this.addMessage('This value must contain only alphabetic characters (A-Z, a-z).');
  }

  private validateNumericOnly(): void {
    const numericRegex = /^\d+$/;
    if (numericRegex.test(this.value)) return;
    this.addMessage('This value must contain only numeric characters (0-9).');
  }

  private validateAlphaNumeric(): void {
    const alphaNumericRegex = /^[a-zA-Z0-9]+$/;
    if (alphaNumericRegex.test(this.value)) return;
    this.addMessage('This value must contain only alphanumeric characters (A-Z, a-z, 0-9).');
  }

  private addMessage(value: string): void {
    if (!value || value.trim() === '') return;
    if (this.message !== '') this.message += '<br>'; 
    this.message += this.sanitizeMessage(value);
  }
  
  private sanitizeMessage(value: string): string {
    const tempDiv = document.createElement('div');
    tempDiv.textContent = value;
    return tempDiv.innerHTML; 
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.cdr.detectChanges();
    setTimeout(this.adjustPopupPosition.bind(this), 0);
  }

  private adjustPopupPosition(): void {
    const triangle = this.elRef.nativeElement.querySelector('.triangle');
    const errorPopup = this.elRef.nativeElement.querySelector('.error-popup');
    if (!triangle || !errorPopup) {
      return;
    }

    const popupRect = errorPopup.getBoundingClientRect();
    const triangleRect = triangle.getBoundingClientRect();
    const triangleBorderSize = parseFloat(getComputedStyle(triangle).borderTopWidth);
    const triangleTipRight = triangleRect.right - triangleBorderSize;
    const rightPosition =
      triangleTipRight + popupRect.width <= window.innerWidth ? -popupRect.width : 0;

    this.renderer.setStyle(errorPopup, 'right', `${rightPosition}px`);
  }

  @HostListener('focusin')
  onFocusIn(): void {
    this.hasFocus = true;
    this.onMouseEnter();
  }

  @HostListener('focusout')
  onFocusOut(): void {
    this.hasFocus = false;
  }
}
