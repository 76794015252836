import { Component, OnInit, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TabsComponent } from "../../common/tabs/tabs.component";
import { UiService } from 'src/app/services/dataServices/uiService/ui.service';
import { UiConfiguration } from 'src/app/services/dataServices/uiService/uiConfiguration';
import { BusyService } from 'src/app/services/busy.service';
import { Subscription } from 'rxjs';

interface TabConfiguration {
  title: string;
  path: string;
}

@Component({
    selector: 'app-body',
    imports: [
        RouterOutlet,
        TabsComponent
    ],
    templateUrl: './body.component.html',
    styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit, OnDestroy {
  
  private defaultTabs: TabConfiguration[] = [
    { title: 'Team Roster', path: '/teamroster' },
    { title: 'Team Readiness', path: '/teamreadiness' },
    { title: 'Training', path: '/training' },
    //{ title: 'Task Summaries', path: '/tasksummaries' },
    //{ title: 'Gear', path: '/gear' },
    //{ title: 'Orders', path: '/orders' },
    { title: 'Reports', path: '/reports' },
    { title: '  ', path: '/' }
  ];
  private adminTab = { title: 'Admin', path: '/admin' };
  private superAdminTab = { title: 'SuperAdmin', path: '/superadmin' };
  private developerTab = { title: 'Developer', path: '/developer' };

  tabs: TabConfiguration[] = [];
  private configSubscription: Subscription | null = null;

  private myConfig: UiConfiguration = new UiConfiguration();

  constructor(private ui: UiService, private busy: BusyService) { }

  ngOnInit(): void {
    this.busy.setBusy(true);

    this.configSubscription = this.ui.uiConfig$.subscribe(config => {
      this.myConfig = config;
      this.configurationUpdated();
    });

    // Trigger initial configuration fetch
    this.ui.getConfiguration().then(() => {
      this.busy.setBusy(false);
    }).catch(() => {
      this.busy.setBusy(false);
    });
  }

  ngOnDestroy(): void {
    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }
  }

  private configurationUpdated(): void {
    this.buildTabs();
  }

  private buildTabs(): void {
    this.tabs = [...this.defaultTabs];
    console.log('BuildTabs Called...');
    try {
      if (this.myConfig.hasRole('Administrator')) this.tabs.push(this.adminTab);
      if (this.myConfig.hasRole('SuperAdmin')) this.tabs.push(this.superAdminTab);
      if (this.myConfig.hasRole('Developer')) this.tabs.push(this.developerTab);
    } catch (error) {
      console.error('Error in hasRole:', error);
    }
  }
}
