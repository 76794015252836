import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalChanged = new BehaviorSubject<string>('');
  modalChanged$ = this.modalChanged.asObservable();

  modalOpen: boolean = false;

  openModal(id: string): void {
    this.modalOpen = true;
    this.modalChanged.next(id);
  }
  closeModal(id: string): void {
    this.modalOpen = false;
    this.modalChanged.next(id);
  }

}
