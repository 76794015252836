import { Component } from '@angular/core';
import { InputBoxComponent } from '../../common/basic/inputbox/inputbox.component';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
import { CheckboxComponent } from '../../common/basic/checkbox/checkbox.component';

@Component({
  selector: 'app-personnel-information-widget',
  imports: [WidgetBaseComponent, InputBoxComponent, CheckboxComponent],
  templateUrl: './personnel-information-widget.component.html',
  styleUrl: './personnel-information-widget.component.scss'
})
export class PersonnelInformationWidgetComponent {
  widgetName: string = 'personnelInformation';
  bureauEod: string = '01/01/2001';
  firstName: string = 'Jonathan';
  middleName: string = 'Barnum';
  lastName: string = 'Smith';
  suffix: string = 'Jr.';
  mobilePhone: string ='123-456-7890';
  businessPhone: string = '123-456-7890, 123-456-7890';
  officialPassport: boolean = true;
  passportExpiration: string = '01/01/2001';
  credentials: string = '123456';

  isEditMode: boolean = true;
}
