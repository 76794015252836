import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ValidationService {
  private groupErrorStates: Record<string, Map<number, boolean>> = {};
  private groupErrorSubjects: Record<string, BehaviorSubject<boolean[]>> = {};
  private allErrorsSubject = new BehaviorSubject<boolean[]>([]);
  private idCounter = 0;

  registerValidator(group: string = 'default'): number {
    const id = this.idCounter++;
    this.modifyGroupState(group, id, false, 'add');
    return id;
  }
  
  unregisterValidator(id: number, group: string = 'default'): void {
    this.modifyGroupState(group, id, null, 'delete');
  }
  
  updateValidator(id: number, hasError: boolean, group: string = 'default'): void {
    this.modifyGroupState(group, id, hasError, 'update');
  }
  
  private modifyGroupState(group: string, id: number, hasError: boolean | null, action: 'add' | 'update' | 'delete'): void {
    const errorStates = this.getErrorStates(group);
    group ||= 'default';
    if (action === 'add') {
      errorStates.set(id, false);
    } else if (action === 'update' && errorStates.has(id)) {
      errorStates.set(id, !!hasError);
    } else if (action === 'delete' && errorStates.has(id)) {
      errorStates.delete(id);
    }
    this.getErrors(group).next([...errorStates.values()]);
    this.allErrorsSubject.next(Object.values(this.groupErrorStates).flatMap(groupState => [...groupState.values()]));
  }

  getErrors$(group: string = ''): BehaviorSubject<boolean[]> {
    return group ? this.getErrors(group) : this.allErrorsSubject;
  }

  private getErrorStates(group: string): Map<number, boolean> {
    return this.groupErrorStates[group] ||= new Map<number, boolean>();
  }

  private getErrors(group: string): BehaviorSubject<boolean[]> {
    return this.groupErrorSubjects[group] ||= new BehaviorSubject<boolean[]>([]);
  }
}
