import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { InputBoxComponent } from 'src/app/components/common/basic/inputbox/inputbox.component';
import { DropdownComponent } from 'src/app/components/common/basic/dropdown/dropdown.component';
import { SpacerComponent } from '../../../../common/basic/spacer/spacer.component';
import { AutocompleteActivedirectoryComponent } from '../../../../common/autocomplete-activedirectory/autocomplete-activedirectory.component';
import { CommonModule } from '@angular/common';
import { ValidationService } from 'src/app/services/validation.service';
import { UiService } from 'src/app/services/dataServices/uiService/ui.service';
import { BaseComponent } from 'src/app/components/common/BaseComponent';
import { CheckboxComponent } from 'src/app/components/common/basic/checkbox/checkbox.component';
import { UiStateService } from 'src/app/services/dataServices/uiService/uiState.service';
import { DualListComponent } from "../../../../common/basic/dual-list/dual-list.component";
import { FormsModule } from '@angular/forms';

interface ListItem {
  id: number | string;
  name: string;
  sortOrder?: number;
}

interface PageModel {
  role: string;
  description: string;
  selectedAccountId: string;
  items: ListItem[];
}

interface PageState {
  required: string,
  alphaOnly: string,
  numericOnly: string,
  alphaNumeric: string,
  minLength: string,
  maxLength: string,
  multipleTests: string,
  dropdownLimitTestValue: string,
  checkboxTestValue: boolean
}

@Component({
    selector: 'app-component-test-page',
    imports: [FormsModule, CommonModule, InputBoxComponent, DropdownComponent, SpacerComponent, AutocompleteActivedirectoryComponent, CheckboxComponent, DualListComponent],
    templateUrl: './component-test-page.component.html',
    styleUrls: ['./component-test-page.component.scss']
})
export class ComponentTestPageComponent extends BaseComponent implements OnInit, OnDestroy {
  pageId: string = 'superadmin.testpage';
  groupValid: boolean = true;
  allValid: boolean = true;
  validationOptions: any = {};
  pageState: PageState = {
    required: '',
    alphaOnly: '',
    numericOnly: '',
    alphaNumeric: '',
    minLength: '',
    maxLength: '',
    multipleTests: '',
    dropdownLimitTestValue: '',
    checkboxTestValue: false
  };
  model: PageModel = {
    role: '',
    description: '',
    selectedAccountId: '',
    items: [
      { id: 1, name: 'Orbit' },
      { id: 2, name: 'Mirage' },
      { id: 3, name: 'Nimbus' },
      { id: 4, name: 'Ember' },
      { id: 5, name: 'Zenith' },
      { id: 6, name: 'Prism' },
      { id: 7, name: 'Cascade' },
      { id: 8, name: 'Valor' },
      { id: 9, name: 'Apex' },
      { id: 10, name: 'Glyph' },
      { id: 11, name: 'Haven' },
      { id: 12, name: 'Quasar' },
      { id: 13, name: 'Echo' },
      { id: 14, name: 'Solstice' },
      { id: 15, name: 'Zephyr' },
      { id: 16, name: 'Nexus' },
      { id: 17, name: 'Luminous' },
      { id: 18, name: 'Horizon' },
      { id: 19, name: 'Arcane' },
      { id: 20, name: 'Eclipse' },
    ],
  };

  // Observable states.
  isDebugMode$ = this.uiStateService.isDebugMode$;

  constructor(private uiStateService: UiStateService, private validationService: ValidationService, ui: UiService, private cdr: ChangeDetectorRef) {
    super(ui);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.addSubscription(
      this.validationService.getErrors$('testValidationGroup').subscribe((errorStates) => {
        this.groupValid = errorStates.every(state => !state);
      })
    );
    
    this.addSubscription(
      this.validationService.getErrors$().subscribe((errorStates) => {
        this.allValid = errorStates.every(state => !state);
      })
    );

    //this.startChangeMonitor();
  }

  onValueChanged(newValue: string): void {
    this.pageState.dropdownLimitTestValue = newValue;
  }




  private changeMonitorInterval: any; // To store the interval reference

  startChangeMonitor(): void {
    this.stopChangeMonitor(); // Ensure no duplicate intervals
    this.changeMonitorInterval = setInterval(() => {
      this.runPeriodicCheck();
    }, 10000); // Run every 10 seconds
  }

  stopChangeMonitor(): void {
    if (this.changeMonitorInterval) {
      clearInterval(this.changeMonitorInterval);
      this.changeMonitorInterval = null;
    }
  }

  private runPeriodicCheck(): void {
    // Your custom function logic
    var serVal = JSON.stringify(this.pageState);
    console.log(serVal);
  }

  // Ensure cleanup in ngOnDestroy
  override ngOnDestroy(): void {
    this.stopChangeMonitor();
    super.ngOnDestroy(); // Call base class cleanup if needed
  }

}
