<footer>
  <div class="footer-left-section ptsNoSelect"
  (click)="releaseNotesClicked()"
  >
    <span>{{ leftText }}</span>
    <small>{{ leftSubtext }}</small>
  </div>
  <div class="footer-right-section ptsNoSelect" title="{{ rightTooltip }}"
  (click)="feedbackClicked()">
    <div>{{ rightText }}</div>
  </div>
</footer>
