import { Component } from '@angular/core';

@Component({
  selector: 'app-my-training',
  imports: [],
  templateUrl: './my-training.component.html',
  styleUrl: './my-training.component.scss'
})
export class MyTrainingComponent {

}
