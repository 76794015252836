<div class="app-header-root">
  <div class="header-logo verticallyCentered"><img class="header-logo-image custom-icon" /></div>
  <div class="header-title">
      <div class="header-text verticallyCentered">
          <div class="header-title-abbrv ptsNoSelect">{{titleAbbrv}}</div>
          <div class="header-title-text ptsNoSelect">{{title}}</div>
      </div>
  </div>
  <div class="header-admin-icons">
      <div class="header-readonly-icon admin-icon" *ngIf="uiStateService.isReadOnlyMode$ | async"></div>
      <div class="header-admin-icons-admin header-admin-icon-section" *ngIf="config.hasRole('Moderator')">
          <app-autocomplete-activedirectory
              [label]="''"
              [placeholder]="'Switch User'"
              (valueChange)="onSwitchUser($event)"
          ></app-autocomplete-activedirectory>
          <div class="header-color-icon admin-icon" *ngIf="uiStateService.isEditMode$ | async" (click)="toggleColorMode()"></div>
          <div class="header-edit-icon admin-icon" (click)="toggleEditMode()"></div>
      </div>
      <div class="header-admin-icons-superadmin header-admin-icon-section" *ngIf="config.hasRole('SuperAdmin')">
          <div class="header-dashedBox-icon admin-icon" (click)="toggleDebugMode()"></div>
      </div>
  </div>
  <div class="header-spacer"></div>
  <app-header-search-bar></app-header-search-bar>
  <app-baseball-card></app-baseball-card>
  <app-colors *ngIf="isEditingColors" [modalId]="colorModalId"></app-colors>
</div>
