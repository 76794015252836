import { Component } from '@angular/core';
import { SearchIcon } from "../../icons/search/search.icon";

@Component({
    selector: 'app-search-bar',
    imports: [SearchIcon],
    templateUrl: './search-bar.component.html',
    styleUrl: './search-bar.component.scss'
})
export class SearchBarComponent {

}
