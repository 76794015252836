import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ModalOverlayComponent } from 'src/app/components/common/modal-overlay/modal-overlay.component';
import { Guid } from 'src/app/customTypes/Guid';
import { InterfaceService } from 'src/app/services/dataServices/interface.service';
import { UiService } from 'src/app/services/dataServices/uiService/ui.service';
import { UiConfiguration } from 'src/app/services/dataServices/uiService/uiConfiguration';
import { ModalService } from 'src/app/services/modal.service';
import { ThemeService } from 'src/app/services/theme.service';

interface InterfaceSetting {
  id: string;
  type: string;
  lang: string;
  value: string;
  oldValue: string;
}

@Component({
    selector: 'app-colors',
    imports: [NgFor, FormsModule, ModalOverlayComponent],
    templateUrl: './colors.component.html',
    styleUrls: ['./colors.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorsComponent implements OnInit, OnDestroy {
  @Input() modalId!: Guid;
  interfaces: InterfaceSetting[] = [];
  config: UiConfiguration = new UiConfiguration();

  constructor(
    private interSvc: InterfaceService,
    private ui: UiService,
    private theme: ThemeService,
    private modal: ModalService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(this.interSvc.interface$.subscribe(ifaces => {
      const colorType = 'color.' + this.theme.getMode();
      if (ifaces && ifaces.length > 0) {
        const filteredInterfaces = ifaces.filter(iface => iface.type && iface.type === colorType);
        if (JSON.stringify(this.interfaces) !== JSON.stringify(filteredInterfaces)) {
          this.interfaces = filteredInterfaces;
          this.interfaces.sort((a, b) => a.id.localeCompare(b.id));
        }
      }
    }));
    this.subscriptions.push(this.ui.uiConfig$.subscribe(conf => {
      if (conf) {
        this.config = conf;
      }
    }));
    this.subscriptions.push(this.modal.modalChanged$.subscribe((id)=>{
      if (id == this.modalId.toString() && !this.modal.modalOpen) {
        this.revertChanges();
      }
    }));
  }

  private revertChanges(): void {
    this.interfaces.forEach(iface => {
      if (iface.oldValue != iface.value) this.changeProperty(iface.id, iface.oldValue);
    });
  }

  private changeProperty(name: string, value: string): void {
    var themeElement: HTMLElement | null = document.querySelector('[current-theme-mode="' + this.theme.getTheme() + '-' + this.theme.getMode() + '"]');
    if (!themeElement) return;
    themeElement.style.setProperty('--' + name, value);
  }

  private subscriptions: Subscription[] = [];
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription=>{
      if(subscription) subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  saveSettings() {
    var type = 'color.' + this.theme.getTheme() + '.' + this.theme.getMode()
    this.interfaces.forEach(iface => {
      if (iface.value != iface.oldValue) this.interSvc.setInterface(iface.id, type, iface.value, false);
    });
    this.interSvc.saveInterfaces();
    this.modal.closeModal(this.modalId.toString());
  }

  onInputChange(iface: InterfaceSetting): void {
    this.changeProperty(iface.id, iface.value)
  }
}
