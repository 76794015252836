import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { LogLevel } from './LogLevel';
import { formatDate } from '@angular/common';
import { DateUtils } from 'src/app/global.extensions';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  // switch (environment.type) {
  //   case "Debug":
  //     console.log(message);
  //     break;
  //   case "Test":
  //     if (logLevel == LogLevel.Verbose) return;
  //     if (logLevel == LogLevel.Info) return;
  //     // Log warnings and errors to database.
  //     console.log(logLevel + " / " + message);
  //     break;
  //   case "Production":
  //     if (logLevel == LogLevel.Verbose) return;
  //     if (logLevel == LogLevel.Info) return;
  //     if (logLevel == LogLevel.Warning) return;
  //     // Log only errors to database.
  //     console.log(logLevel + " / " + message);
  //     break;
  //   default:
  //     throw new Error("Unknown environment type: " + environment.type);
  // }

  log(message: string, level: LogLevel) {
    // Grab timestamp first since the following will take time.
    var timestamp = DateUtils.timestamp();

    // For MSAL logs, strip off the unnecessary excess data.
    if ((<number>level & LogLevel.IsMsal) == LogLevel.IsMsal) {
      return;
      var index = message.indexOf(' : ');
      if (index > -1) {
        index = message.indexOf(' : ', index+1);
        if (index > -1) {
          message = message.substring(index+3);
        }
      }
    }

    // Store the current stacktrace settings.
    var stackTraceLimit = Error.stackTraceLimit;

    // Set it to infinity.
    Error.stackTraceLimit = Infinity;

    // Create a new stack trace.
    var stackTrace = '' + (new Error()).stack?.toString();

    // Put the settings back.
    Error.stackTraceLimit = stackTraceLimit;

    //console.log(stackTrace);
    //var foo = stackTrace.match(/at \w+\.\w+/);
    //console.log(foo?.length);

    console.log(timestamp + ": " + message);
  }
}
