import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(startDate: Date, endDate: Date): string {
    const start = new Date(startDate);
    const end = new Date(endDate);

    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();
    let days = end.getDate() - start.getDate();

    // Adjust for negative days
    if (days < 0) {
      const previousMonth = new Date(end.getFullYear(), end.getMonth(), 0); // Last day of the previous month
      days += previousMonth.getDate(); // Add the days of the previous month
      months--;
    }

    // Adjust for negative months
    if (months < 0) {
      months += 12;
      years--;
    }

    return `${years}y, ${months}m, ${days}d`;
  }
}
