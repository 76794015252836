import { Injectable } from '@angular/core';
import { DataServiceBase } from '../dataServiceBase';
import { UiConfiguration } from './uiConfiguration';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService extends DataServiceBase {
  private configuration = new UiConfiguration();
  private nextUpdate: number = 0;
  private defaultPhoto: string = "";

  private configSubject = new BehaviorSubject<UiConfiguration>(this.configuration);
  uiConfig$ = this.configSubject.asObservable();

  async setConfiguration(config: UiConfiguration): Promise<void> {
    this.configuration = Object.assign(new UiConfiguration(), config);
    this.configSubject.next(this.configuration);
  }

  public async getConfiguration(): Promise<UiConfiguration> {
    await this.updateConfig();
    return this.configuration;
  }

  private async updateConfig(): Promise<void> {
    const now = Date.now();
    if (this.nextUpdate > now) return;

    try {
      const apiResponse = await this.get('ui/myconfiguration');
      this.configuration = Object.assign(new UiConfiguration(), apiResponse);
      this.configuration.profilePhoto = await this.getProfilePhoto(this.configuration.webId);
    } catch (error) {
      console.error('Error fetching configuration:', error);
    }
    this.configSubject.next(this.configuration);
    this.nextUpdate = Date.now() + 5 * 60 * 1000;
  }

  public async setUserConfiguration(webId: string): Promise<void> {
    if (!webId || webId.trim() === '') {
      throw new Error('WebId must be provided.');
    }

    try {
      const apiResponse = await this.getById('ui/userconfiguration', webId);
      this.configuration = Object.assign(new UiConfiguration(), apiResponse);
      this.configuration.profilePhoto = await this.getProfilePhoto(webId);
    } catch (error) {
      console.error(`Error fetching configuration for WebId ${webId}:`, error);
      throw error;
    }

    this.configSubject.next(this.configuration);
  }

  private async getProfilePhoto(webId: string): Promise<string> {
    if (!webId) {
      throw new Error('WebId cannot be null or empty.');
    }
    try {
      const blob = await this.getBlobById('ui/ProfilePhoto', webId);
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error(`Error fetching profile photo for WebId ${webId}:`, error);
      throw error;
    }
  }

  public async getUserConfiguration(webId: string): Promise<UiConfiguration> {
    if (this.defaultPhoto == "") this.defaultPhoto = await this.getProfilePhoto("PROFIMGDEFAULT");

    if (!webId || webId.trim() === '') {
        throw new Error('WebId must be provided.');
    }

    try {
        const apiResponse = await this.getById('ui/userconfiguration', webId);

        let configResult: UiConfiguration;
        if (!apiResponse) {
          configResult = UiConfiguration.Empty;
          configResult.profilePhoto = this.defaultPhoto;
        } else {
          configResult = Object.assign(new UiConfiguration(), apiResponse);
          configResult.profilePhoto = await this.getProfilePhoto(webId);
        }
        return configResult;
    } catch (error: any) {
        console.error(`Error fetching user configuration for WebId ${webId}:`, error);
        throw error;
    }
  }

  
  
}
