import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TokenService } from "../token.service";
import { lastValueFrom, Observable } from "rxjs";
import { UiStateService } from "src/app/services/dataServices/uiService/uiState.service";

@Injectable({
  providedIn: 'root'
})
export class DataServiceBase {
  protected version = 'v1';

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private uiStateService: UiStateService // Inject UiStateService
  ) {
    this.initialize();
  }

  protected async initialize(): Promise<void> {}

  private async handleError(error: any): Promise<void> {
    try {
      const options = await this.tokenService.getOptions(false);
      // Handle the case where the response is binary (blob)
      if (error?.error instanceof Blob) {
        console.error('HTTP Error (Blob Response):', error.error);
      } else {
        // Fallback to text if it's not binary
        options.responseType = 'text';
        const errorResponse = await lastValueFrom(this.http.get(error.url, options));
        console.error('HTTP Error (Text Response):', errorResponse);
      }
    } catch (textError) {
      console.error('Failed to read error response as text:', textError);
    }
    throw error;
  }
  

  protected async get(url: string): Promise<any> {
    if (!url.endsWith('/')) url = url + '/';
    const fullUrl = `/api/${this.version}/${url}`;
    const options = await this.tokenService.getOptions(false);
    
    try {
        const response = await lastValueFrom(this.http.get<any>(fullUrl, options));
        return DataServiceBase.toCamelCaseKeys(response);
    } catch (error: any) {
        if (error.status === 404) {
            return null;
        }
        await this.handleError(error);
        throw error;
    }
  }

  protected async getById(url: string, webId: string): Promise<any> {
      if (!url.endsWith('/')) url = url + '/';
      const fullUrl = `/api/${this.version}/${url}${webId}`;
      const options = await this.tokenService.getOptions(false);
      
      try {
          const response = await lastValueFrom(this.http.get<any>(fullUrl, options));
          return DataServiceBase.toCamelCaseKeys(response);
      } catch (error: any) {
          if (error.status === 404) {
              return null;
          }
          await this.handleError(error);
          throw error;
      }
  }

  protected async getWithParams(url: string, params?: { [key: string]: any }): Promise<any> {
      if (url.endsWith('/')) url = url.slice(0, -1);
      const fullUrl = `/api/${this.version}/${url}`;
      const options = await this.tokenService.getOptions(false);
      options.params = params ? new HttpParams({ fromObject: params }) : undefined;
      
      try {
          const response = await lastValueFrom(this.http.get<any>(fullUrl, options));
          return DataServiceBase.toCamelCaseKeys(response);
      } catch (error: any) {
          if (error.status === 404) {
              return null;
          }
          await this.handleError(error);
          throw error;
      }
  }




  protected async getBlobById(url: string, webId: string): Promise<Blob> {
    if (!url.endsWith('/')) url = url + '/';
    const fullUrl = `/api/${this.version}/${url}${webId}`;
    // Retrieve base options from tokenService
    const baseOptions = await this.tokenService.getOptions(false);
    // Override responseType and observe for binary data
    const options = {
      ...baseOptions,
      responseType: 'blob' as const, // Ensure responseType is treated as 'blob'
      observe: 'body' as const,      // Ensure only the body is returned
    };
    try {
      const response = await lastValueFrom(this.http.get<Blob>(fullUrl, options));
      return (response as unknown) as Blob;
    } catch (error: any) {
      console.log('Error thrown in GET');
      await this.handleError(error);
      throw error;
    }
  }
  
  



  protected async post(url: string, payload: any): Promise<any> {
    if (this.uiStateService.getReadOnlyMode()) {
      console.warn(`POST request to ${url} ignored in read-only mode.`);
      return Promise.resolve(null); // Pretend it succeeded
    }

    const fullUrl = `/api/${this.version}/${url}`;
    const options = await this.tokenService.getOptions();
    let response: any;
    try {
      response = await lastValueFrom(this.http.post<any>(fullUrl, payload, options));
      return DataServiceBase.toCamelCaseKeys(response);
    } catch (error: any) {
      this.handleError(error);
    }
  }

  protected async put(url: string, payload: any): Promise<any> {
    if (this.uiStateService.getReadOnlyMode()) {
      console.warn(`PUT request to ${url} ignored in read-only mode.`);
      return Promise.resolve(null); // Pretend it succeeded
    }

    const fullUrl = `/api/${this.version}/${url}`;
    const options = await this.tokenService.getOptions();
    let response: any;
    try {
      response = await lastValueFrom(this.http.put<any>(fullUrl, payload, options));
      return DataServiceBase.toCamelCaseKeys(response);
    } catch (error: any) {
      this.handleError(error);
    }
  }

  private static toCamelCaseKeys(obj: any): any {
    if (obj === null) return null;
    if (typeof obj !== 'object') return obj; // Return as is if it's not an object
    if (Array.isArray(obj)) return obj.map(item => DataServiceBase.toCamelCaseKeys(item));
    const toCamelCase = (str: string): string => {
      return str
        .replace(/([-_][a-z])/g, (group) => group[1].toUpperCase()) // Convert to uppercase
        .replace(/^./, (firstChar) => firstChar.toLowerCase()); // Lowercase the first character
    };

    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = toCamelCase(key);
      acc[camelKey] = DataServiceBase.toCamelCaseKeys(obj[key]); // Recursive call for nested objects
      return acc;
    }, {} as any);
  }
}
