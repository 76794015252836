import { Component } from '@angular/core';
import { OrganizationInformationWidgetComponent } from 'src/app/components/widgets/organization-information-widget/organization-information-widget.component';
import { PersonnelInformationWidgetComponent } from 'src/app/components/widgets/personnel-information-widget/personnel-information-widget.component';
import { AssignmentHistoryWidgetComponent } from 'src/app/components/widgets/assignment-history-widget/assignment-history-widget.component';
import { UserProfileWidgetComponent } from 'src/app/components/widgets/user-profile-widget/user-profile-widget.component';
import { TrainingHistoryWidgetComponent } from 'src/app/components/widgets/training-history-widget/training-history-widget.component';
import { RequirementsRespiratorsWidgetComponent } from 'src/app/components/widgets/requirements-respirators-widget/requirements-respirators-widget.component';
import { RequirementsPhysicalsWidgetComponent } from 'src/app/components/widgets/requirements-physicals-widget/requirements-physicals-widget.component';
import { SkillsCompetenciesEvaluationsWidgetComponent } from 'src/app/components/widgets/skills-competencies-evaluations-widget/skills-competencies-evaluations-widget.component';
import { ProfileModel } from './ProfileModel';
import { StoplightWidgetComponent } from 'src/app/components/widgets/stoplight-widget/stoplight-widget.component';
import { UiConfiguration } from 'src/app/services/dataServices/uiService/uiConfiguration';
import { filter, firstValueFrom, take } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UiService } from 'src/app/services/dataServices/uiService/ui.service';

@Component({
  selector: 'app-profile',
  imports: [
    UserProfileWidgetComponent,
    OrganizationInformationWidgetComponent,
    PersonnelInformationWidgetComponent,
    AssignmentHistoryWidgetComponent,
    RequirementsRespiratorsWidgetComponent,
    RequirementsPhysicalsWidgetComponent,
    SkillsCompetenciesEvaluationsWidgetComponent,
    StoplightWidgetComponent
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {
  config: UiConfiguration = new UiConfiguration();
  profilePhoto: string = '';
  id: string = '';

  constructor(private uiService: UiService, private route: ActivatedRoute){}

  async ngOnInit() {
    this.id = (await firstValueFrom(this.route.paramMap)).get('id')!;
    
    if (this.id) {
      try {
        const profileConfig = await this.uiService.getUserConfiguration(this.id);
        this.config = profileConfig;
        this.profilePhoto = profileConfig.profilePhoto;
      } catch (error) {
        console.error(`Error fetching profile config for WebId ${this.id}:`, error);
      }
    }
  }
}
