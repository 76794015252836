import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FocusTrapDirective } from 'src/app/directives/focus-trap.directive';
import { ModalService } from 'src/app/services/modal.service';

@Component({
    selector: 'app-modal-overlay',
    templateUrl: './modal-overlay.component.html',
    styleUrls: ['./modal-overlay.component.scss'],
    imports: [CommonModule, FocusTrapDirective]
})
export class ModalOverlayComponent implements OnInit {
  @Input() id!: string;

  isVisible = false;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.isVisible = true;
    this.modalService.openModal(this.id);
  }

  closeModal() {
    this.isVisible = false;
    this.modalService.closeModal(this.id);
  }
}
