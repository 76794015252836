import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { UiService } from 'src/app/services/dataServices/uiService/ui.service';
import { Subscription } from 'rxjs';
import { UiConfiguration } from 'src/app/services/dataServices/uiService/uiConfiguration';

@Component({
    selector: 'app-baseball-card',
    imports: [MatTooltipModule, CommonModule],
    templateUrl: './baseball-card.component.html',
    styleUrls: ['./baseball-card.component.scss']
})
export class BaseballCardComponent implements OnInit {
  constructor(
    private router: Router,
    private uiService: UiService
  ) {}
  
  name: string = "Loading...";
  profilePhoto: string = '';
  badgeLeftTooltip: string = "You have new messages.";
  badgeRightTooltip: string = "System alert!";
  config: UiConfiguration = new UiConfiguration();

  async ngOnInit() {
    this.subscriptions.push(this.uiService.uiConfig$.subscribe((config) => {
      this.config = config;
      this.name = config.friendlyName;
      this.profilePhoto = config.profilePhoto;
    }));
  }

  private subscriptions: Subscription[] = [];
  ngOnDestroy(): void {
    console.log("Destroy being called.")
    this.subscriptions.forEach(subscription=>{
      if(subscription) subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  alertClicked() {}

  messagesClicked() {}

  profileClicked() {
    this.router.navigate(['profile', this.config.webId.trim()]);
  }
}
