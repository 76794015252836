<div class="input-wrapper">
  <app-label
    [id]="id"
    [text]="label"
    [style.min-width]="labelWidth"
  ></app-label>
  <app-validator [options]="validationOptions" [value]="value" [errors]="errors" [group]="validationGroup">
    <ng-container *ngIf="!editable; else editableContent">
      <ng-container *ngIf="multiline; else singleLineDiv">
        <div class="non-editable-textarea">{{ value }}</div>
      </ng-container>
      <ng-template #singleLineDiv>
        <div class="non-editable-input">{{ value }}</div>
      </ng-template>
    </ng-container>
    <ng-template #editableContent>
      <ng-container *ngIf="multiline; else singleLineInput">
        <textarea
          [id]="id"
          [value]="value"
          [placeholder]="placeholder"
          (input)="onValueChange($event.target)"
        ></textarea>
      </ng-container>
      <ng-template #singleLineInput>
        <input
          [id]="id"
          [value]="value"
          type="text"
          [placeholder]="placeholder"
          (input)="onValueChange($event.target)"
        />
      </ng-template>
    </ng-template>
  </app-validator>
  <app-info [id]="id" [info]="info"></app-info>
</div>
