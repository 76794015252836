export class UiConfiguration {
  friendlyName: string = "";
  profilePhoto: string = "";
  webId: string = "";
  roles: string[] = ["BaseUser"];
  defaultPage: string = "";
  lastUrl: string = "";
  systemAlerts = 0;
  unreadMessages = 0;

  static readonly Empty = new UiConfiguration();

  public hasRole(role: string): boolean {
    if (!Array.isArray(this.roles)) return false;
    const lowerCaseRole = role.toLowerCase();
    return this.roles.some(r => typeof r === 'string' && r.toLowerCase() === lowerCaseRole);
  }
}
