import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';

if (environment.type === "Production") {
  enableProdMode();
}

function preloadCss(): Promise<void> {
  return new Promise((resolve) => {
    const stylesheets = document.querySelectorAll('link[rel="stylesheet"]');
    let loadedCount = 0;

    if (stylesheets.length === 0) {
      resolve();
      return;
    }

    stylesheets.forEach(link => {
      if (link instanceof HTMLLinkElement) {
        if (link.sheet) {
          loadedCount++;
          if (loadedCount === stylesheets.length) resolve();
        } else {
          link.addEventListener('load', () => {
            loadedCount++;
            if (loadedCount === stylesheets.length) resolve();
          });
        }
      }
    });
  });
}

preloadCss().then(() => {
  bootstrapApplication(AppComponent, appConfig).catch((err) =>
    console.error(err)
  );
});
