<div class="selector-table-wrapper">
  <table class="selector-table">
    <thead>
      <tr>
        <th *ngFor="let column of columns" [style.width]="column.width || 'auto'">
          {{ column.label }}
        </th>
      </tr>
    </thead>
  </table>

  <div class="table-body-wrapper">
    <table class="selector-table">
      <tbody>
        <tr *ngFor="let row of rows" [ngClass]="{'disabled-row': isRowDisabled(row)}">
          <td *ngFor="let column of columns">
            <ng-container *ngIf="column.isCheckbox; else textCell">
              <input
                type="checkbox"
                [checked]="row[column.key]"
                [disabled]="isRowDisabled(row) && column.key !== 'selected'"
                (change)="onCheckboxChange(row, column.key, $event)"
              />
            </ng-container>
            <ng-template #textCell>{{ row[column.key] }}</ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
