import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Guid } from 'src/app/customTypes/Guid';
import { InterfaceService } from 'src/app/services/dataServices/interface.service';
import { ModalService } from 'src/app/services/modal.service';
import { ModalOverlayComponent } from '../../modal-overlay/modal-overlay.component';
import { UiStateService } from 'src/app/services/dataServices/uiService/uiState.service';
import { LabelComponent } from '../label/label.component';

@Component({
    selector: 'app-textbox',
    templateUrl: './textbox.component.html',
    styleUrls: ['./textbox.component.scss'],
    imports: [CommonModule, ModalOverlayComponent, LabelComponent]
})
export class TextboxComponent implements OnInit, OnDestroy {
  @Input() id!: string;
  @Input() text: string = 'No Text Set';
  @Input() editable: boolean = true;

  modalId = Guid.newGuid(); // ID for this specific modal
  isEditing = false; // Tracks whether the modal for this label is open
  isEditMode = false; // Tracks global edit mode state from UiStateService

  private subscriptions: Subscription[] = [];

  constructor(
    private interfaceSvc: InterfaceService,
    private modal: ModalService,
    private uiStateService: UiStateService // Global edit mode
  ) {}

  ngOnInit(): void {
    if (!this.editable) return;

    // Subscribe to interface updates for the label text
    this.subscriptions.push(
      this.interfaceSvc.interface$.subscribe(() => {
        const newText = this.interfaceSvc.getInterface(this.id, 'label');
        if (newText.startsWith('NF:[')) return; // Ignore not-found labels
        if (newText) this.text = newText;
      })
    );

    // Subscribe to global edit mode from UiStateService
    this.subscriptions.push(
      this.uiStateService.isEditMode$.subscribe((isEditMode) => {
        this.isEditMode = isEditMode; // Track global edit mode
      })
    );

    // Handle modal state changes
    this.subscriptions.push(
      this.modal.modalChanged$.subscribe((id: string) => {
        if (!this.modal.modalOpen && id === this.modalId.toString()) {
          this.isEditing = false; // Close the modal
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  toggleModal(): void {
    if (!this.isEditMode || !this.editable) return; // Ensure edit mode is enabled
    this.isEditing = true; // Open the modal
  }

  doSave(value: string): void {
    this.isEditing = false; // Close the modal after saving
    this.text = value;
    this.interfaceSvc.setInterface(this.id, 'label', this.text, true); // Save the new label
  }
}
