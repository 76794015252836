import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelComponent } from '../label/label.component';
import { ValidatorComponent } from '../../validator/validator.component';
import { InfoComponent } from '../../info/info.component';
import { StatefulComponent } from '../../StatefulComponent';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    imports: [CommonModule, LabelComponent, ValidatorComponent, InfoComponent]
})
export class CheckboxComponent extends StatefulComponent {
  @Input() label: string = 'Checkbox:';
  @Input() validationOptions: any = {};
  @Input() labelWidth: string = '10px';
  @Input() errors: string = '';
}
