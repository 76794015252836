import { Component } from '@angular/core';

@Component({
  selector: 'app-gear',
  imports: [],
  templateUrl: './gear.component.html',
  styleUrl: './gear.component.scss'
})
export class GearComponent {

}
