<app-autocomplete-list 
  [id]="id"
  [listHeight]="listHeight"
  [placeholder]="placeholder"
  [label]="label"
  [validationOptions]="validationOptions"
  [validationGroup]="validationGroup"
  [errors]="errors"
  [items]="items"
  [manualFilter]="true"
  (inputChanged)="onInputChanged($event)"
  (itemSelected)="onItemSelected($event)"
/>