<div class="checkbox-wrapper">
  <app-label
    [id]="id"
    [text]="label"
    [style.min-width]="labelWidth"
  ></app-label>
  <app-validator [errors]="errors" [options]="validationOptions" [value]="value.toString()">
    <div class="checkbox-container">
      <input
        [id]="id"
        type="checkbox"
        [checked]="value"
        (change)="onValueChange($event.target)"
        [disabled]="!enabled"
      />
    </div>
  </app-validator>
  <app-info [id]="id"></app-info>
</div>
